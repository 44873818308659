import { Button, Spacer } from "@mailbrew/uikit";
import ImagesShow from "components/editor/ImagesShow";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import PostBodyOption from "components/editor/PostBodyOption";
import SearchField from "components/editor/SearchField";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import InfoButton from "components/InfoButton";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { addSource, setSourceBeingEditedIndex, updateSourceField } from "reducers/newslettersReducer";
import { pluralize } from "utils/pluralize";
import { searchRssFeeds } from "utils/sourceSuggestionFetchers";
import { v4 as uuidv4 } from "uuid";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const RssFeedSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const sourceIsValid = source?.url;

  const dispatch = useDispatch();

  const handleFeedUrlSubmit = (_, __, feed) => {
    if (!feed) return;
    dispatch(updateSourceField(sourceIndex, "url", feed.feed_url));
    dispatch(updateSourceField(sourceIndex, "title", feed.title));
  };

  function handleAddAnother() {
    dispatch(
      addSource({
        source: {
          ...source,
          id: uuidv4(),
          url: "",
        },
        index: sourceIndex + 1,
      })
    );
    dispatch(setSourceBeingEditedIndex(sourceIndex + 1));
  }

  return (
    <SourceEditorWrapper>
      {/* FEED URL */}
      <SourceEditorSection title="Website" icon="link" noBorderTop>
        <SearchField
          key={source.url}
          initialValue={source.url}
          placeholder="daringfireball.net"
          fetchSuggestions={searchRssFeeds}
          onSuggestionClick={handleFeedUrlSubmit}
          autofillSuggestionClick="empty"
          autoFocus={!(source?.title?.length > 0)}
        />
        <Spacer size={2} />
        <InfoButton
          title="Let us find RSS for you"
          text="We'll find its RSS feed for you, but you can also paste an RSS URL directly."
        >
          Type a website name or paste a URL
        </InfoButton>
      </SourceEditorSection>

      {sourceIsValid && (
        <Fragment>
          {/* NUMBER OF POSTS */}
          <NumPostsSourceEditor
            title={`Include ${source.num_posts} most recent ${pluralize("post", source.num_posts)}`}
            source={source}
            sourceIndex={sourceIndex}
            min={1}
            max={30}
            fieldName="num_posts"
          />

          <SourceStyleOptionsWrapper>
            <SourceOptionGrid>
              <ImagesShow
                state={source.show_images === undefined ? true : source.show_images}
                onChange={() => dispatch(updateSourceField(sourceIndex, "show_images", !source.show_images))}
                pt={0}
                pb={0}
              />
              <PostBodyOption
                source={source}
                sourceIndex={sourceIndex}
                disabled={source.quote_mode}
                defaultOption="excerpt"
              />
              <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
            </SourceOptionGrid>
            <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
            <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
          </SourceStyleOptionsWrapper>
          <SourceEditorSection noBorderTop noBorderBottom>
            <Button mb={2} onClick={handleAddAnother} mt={1} w="100%" icon="plus" variant="secondary">
              Add another
            </Button>
          </SourceEditorSection>
        </Fragment>
      )}
    </SourceEditorWrapper>
  );
};

export default RssFeedSourceEditor;
