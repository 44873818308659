import { P2 } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { SourceSizeSetting } from "components/SourceStyleEditor";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const SeparatorSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <SourceEditorWrapper>
      <SourceEditorSection noBorderTop>
        <P2>Use a separator to better divide different sections of your brew.</P2>
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceSizeSetting source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default SeparatorSourceEditor;
