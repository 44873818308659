import { P1, H4, HStack, Button, Icon, Spacer, Modal } from "@mailbrew/uikit";

const InfoModal = (props) => {
  const { show, setShow, title, body, icon, video, image, width, iconColor } = props;

  return (
    <Modal width={width} show={show} setShow={setShow} fullScreenAt={video ? 480 : 0}>
      {icon && (
        <>
          <Icon name={icon} color={iconColor} size="2em" mb={2} />
        </>
      )}
      <H4 mb={2}>{title || "Are you sure?"}</H4>
      {body && (
        <P1 style={{ whiteSpace: "pre-line" }}>
          <span dangerouslySetInnerHTML={{ __html: body }}></span>
        </P1>
      )}
      {props.children}
      {video && (
        <>
          <video style={{ margin: "1em 0" }} width="100%" height="auto" autoPlay loop playsInline>
            <source src={`/videos/${video}.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      )}
      {image && (
        <>
          <img style={{ margin: "1em 0" }} alt={title} width="100%" height="auto" src={`/images/${image}`} />
        </>
      )}
      <Spacer size="xxs" />
      <HStack align="right">
        <Button width="8em" onClick={() => setShow(false)}>
          Okay
        </Button>
      </HStack>
    </Modal>
  );
};

export default InfoModal;
