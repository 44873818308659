import { SegmentedControl, SmallText, Spacer, useConfig } from "@mailbrew/uikit";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxLinksSection from "components/MaxLinksSection";
import SharesStyleSetting from "components/SharesStyleSetting";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import TwitterListEditorSection from "components/TwitterListEditorSection";
import { useEffect } from "react";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import { notifySourcePreviewForReload } from "utils/cutom-events";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const minLinks = 1;
const maxLinksLimit = 15;

const TwitterListSourceEditor = (props) => {
  const { source, sourceIndex } = props;
  const config = useConfig();

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const { data: lists, error: listsApiError } = useSWR("twitter_lists/", { revalidateOnFocus: true });

  useEffect(() => {
    const list = lists?.find((l) => l.id === source.list_id);
    if (list) {
      dispatch(updateSourceField(sourceIndex, "ft_list_title", list.name));
    }
  }, [dispatch, lists, source.list_id, sourceIndex]);

  const onSelectTwitterList = (listId) => {
    const list = lists.find((l) => l.id === listId);
    dispatch(updateSourceField(sourceIndex, "list_id", list.id));
  };

  const onSwitchHomeListLinks = (type) => {
    dispatch(updateSourceField(sourceIndex, "use_home_timeline", type === "timeline"));
  };

  const handleConnectedTwitterAccountChange = () => {
    notifySourcePreviewForReload(sourceIndex);
  };

  return (
    <SourceEditorWrapper>
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        <SourceEditorSection noBorderTop>
          <SmallText display="block" align="center" color={config.colors.c2} mb={1}>
            Whose tweets should be check?
          </SmallText>
          <SegmentedControl
            options={["timeline", "list"]}
            optionsNames={["My follows", "From a list"]}
            active={source.use_home_timeline ? "timeline" : "list"}
            onOptionChange={onSwitchHomeListLinks}
            backdropStyle={{
              ...config.SegmentedControl.backdropStyle,
              background: config.colors.twitter,
            }}
            mb={source.use_home_timeline ? 0 : 3}
          />
          {!source.use_home_timeline && (
            <TwitterListEditorSection
              source={source}
              lists={lists}
              error={listsApiError}
              onSelectTwitterList={onSelectTwitterList}
            />
          )}
        </SourceEditorSection>

        {/* MAX NUMBER OF LINKS */}
        <MaxLinksSection source={source} sourceIndex={sourceIndex} min={minLinks} max={maxLinksLimit}>
          <SettingSwitchRow
            state={source.min_shares_to_include === 1}
            onChange={() =>
              dispatch(
                updateSourceField(sourceIndex, "min_shares_to_include", source.min_shares_to_include === 1 ? 2 : 1)
              )
            }
            copy="Include links with only one share"
            tooltip="If you see too much noise you can try disabling this to remove links shared only by one user."
            color={config.colors.c3}
            mt={3}
          />
        </MaxLinksSection>

        <SourceStyleOptionsWrapper>
          <SharesStyleSetting source={source} sourceIndex={sourceIndex} />
          <Spacer size={2} />
          <SourceOptionGrid>
            <ImagesStyleSetting
              allowWideThumbnails
              style={source.images_style}
              onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
              pb={0}
              pt={0}
            />
            <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
          </SourceOptionGrid>
          <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
          <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
        </SourceStyleOptionsWrapper>
      </Disable>

      {twitterAccountConnected && (
        <SourceEditorSection noPadding noBorderBottom>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

export default TwitterListSourceEditor;
