import { Card, Grid, P1, Spacer, useConfig } from "@mailbrew/uikit";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { move, remove } from "utils/array";
import { searchPodcasts } from "utils/sourceSuggestionFetchers";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import PostBodyOption from "../PostBodyOption";
import SearchField from "../SearchField";
import { DragHandle } from "../SourceCard";
import SourceEditorSection from "../SourceEditorSection";
import SourceEditorWrapper from "../SourceEditorWrapper";
import SourceOptionGrid from "../SourceOptionGrid";
import { RemoveButton } from "components/RemoveButton";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import InfoButton from "components/InfoButton";
import SettingSwitchRow from "../SettingSwitchRow";

export default function PodcastSourceEditor({ source, sourceIndex }) {
  const dispatch = useDispatch();
  const config = useConfig();

  const showApplePodcastShortcut = source?.show_apple_podcast_shortcut ?? true;

  const onSuggestionClick = (_, __, payload) => {
    if (!payload) return;
    const newPodcasts = [...source.podcasts, makePodcastFromSuggestion(payload)];
    updatePodcasts(newPodcasts);
  };

  const didReachFeedPerSourceLimit = () => {
    return source.podcasts.length >= 20;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    const newPodcasts = move(source.podcasts, result.source.index, result.destination.index);
    updatePodcasts(newPodcasts);
  };

  const onRemoveClick = (podcastToRemove) => {
    const newPodcasts = remove(source.podcasts, (el) => {
      return el.itunesId === podcastToRemove.itunesId;
    });
    updatePodcasts(newPodcasts);
  };

  const updatePodcasts = (newPodcasts) => {
    dispatch(updateSourceField(sourceIndex, "podcasts", newPodcasts));
  };

  const toggleShowApplePodcastShortcut = () => {
    dispatch(updateSourceField(sourceIndex, "show_apple_podcast_shortcut", !showApplePodcastShortcut));
  };

  return (
    <SourceEditorWrapper>
      <SourceEditorSection title="Your Podcasts">
        <SearchField
          placeholder="Search by podcast name..."
          autoFocus={true}
          fetchSuggestions={searchPodcasts}
          onSuggestionClick={onSuggestionClick}
          autofillSuggestionClick="empty"
          disabled={didReachFeedPerSourceLimit()}
        />
        <Spacer size={2} />
        <InfoButton
          title="You have a private podcast feed?"
          text="You can also directly paste the RSS URL and we'll find the feed for you."
        >
          Type a podcast name or paste a RSS URL
        </InfoButton>

        {didReachFeedPerSourceLimit() && (
          <P1 mt={2} color={config.colors.accent1}>
            You reached the limit of feeds for this source. Create another Podcasat source to add more podcasts.
          </P1>
        )}

        {source.podcasts.length > 0 && <Spacer />}
        {source.podcasts.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              // Reparenting to fix an issue with a parent who has a transform
              // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
              renderClone={(provided, _, rubric) => {
                const podcast = source.podcasts?.[rubric.source.index];
                if (!podcast) return null;
                return (
                  <PodcastRow
                    title={podcast.name}
                    imageURL={podcast.imageURL}
                    onRemoveClick={() => onRemoveClick(podcast)}
                    provided={provided}
                  />
                );
              }}
              droppableId="droppable"
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {source.podcasts.map((podcast, index) => (
                    <Draggable
                      key={`${podcast.itunesId}-${index}`}
                      draggableId={`${podcast.itunesId}-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <PodcastRow
                          title={podcast.name}
                          imageURL={podcast.imageURL}
                          onRemoveClick={() => onRemoveClick(podcast)}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Spacer size={2} />
        <SettingSwitchRow
          copy="Include shortcut to Apple Podcast"
          state={showApplePodcastShortcut}
          onChange={toggleShowApplePodcastShortcut}
        />
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <Spacer size={2} />
        <SourceOptionGrid>
          <ImagesStyleSetting
            style={source.images_style}
            useSmallThumbnails={true}
            onChange={(layout) => {
              dispatch(updateSourceField(sourceIndex, "images_style", layout));
            }}
            pt={0}
            pb={0}
          />
          <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
          <PostBodyOption source={source} sourceIndex={sourceIndex} defaultOption="excerpt" />
        </SourceOptionGrid>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
}

export function makePodcastFromSuggestion(payload) {
  return {
    itunesId: payload.id,
    name: payload.name,
    author: payload.author,
    marketingURL: payload.marketing_url,
    feedURL: payload.feed_url,
    category: payload.category,
    imageURL: payload.image_url,
    applePodcastURL: payload.apple_podcast_url,
  };
}

const PodcastRow = ({ title, imageURL, onRemoveClick, provided }) => {
  const config = useConfig();

  const { style: draggableStyle, ...otherDraggableProps } = provided?.draggableProps ?? {};
  const gridColumns = imageURL ? "auto 1fr auto auto auto" : "1fr auto auto auto";

  return (
    <Card
      inline
      mb={2}
      pl={2}
      py={0}
      pr={0}
      radius={config.appearance.smallRadius}
      h="44px"
      style={{ ...draggableStyle }}
      flex
      ai="center"
      ref={provided?.innerRef}
      {...otherDraggableProps}
    >
      <Grid columns={gridColumns} vAlign="center" width="100%" gap={3}>
        <img
          src={imageURL}
          alt={title}
          style={{ width: "1.6em", height: "1.6em", borderRadius: "4px", objectFit: "contain" }}
        />
        <P1 noWrap>{title}</P1>

        <RemoveButton onClick={onRemoveClick} />
        <DragHandle color={config.colors.c3} {...provided?.dragHandleProps} />
      </Grid>
    </Card>
  );
};
