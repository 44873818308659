import CompactSelectSetting from "./CompactSelectSetting";

const ImagesStyleSetting = (props) => {
  const { label = "Images", style = "full", allowWideThumbnails, useSmallThumbnails = false, onChange, disabled, ...rest } = props;

  const thumb = useSmallThumbnails ? "small_thumb_left" : "thumb"
  const layouts = allowWideThumbnails ? ["hidden", thumb, "wide_thumb", "full"] : ["hidden", thumb, "full"];
  const layoutsNames = allowWideThumbnails
    ? ["No images", "Thumbnails", "Wide", "Full"]
    : ["No images", "Thumbnails", "Full"];

  return (
    <CompactSelectSetting
      label={label}
      icon="picture"
      selectedOption={style}
      onChange={onChange}
      disabled={disabled}
      optionsIds={layouts}
      optionsNames={layoutsNames}
      {...rest}
    />
  );
};

export default ImagesStyleSetting;
