import { SegmentedControl, useConfig } from "@mailbrew/uikit";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";

export default function PopularOrLatestSelector({ source, sourceIndex, optionsNames = ["Latest", "Most Popular"] }) {
  const dispatch = useDispatch();
  const config = useConfig();
  return (
    <SegmentedControl
      options={["latest", "most_popular"]}
      optionsNames={optionsNames}
      active={source.mode}
      onOptionChange={(mode) => {
        dispatch(updateSourceField(sourceIndex, "mode", mode));
      }}
      backdropStyle={{
        ...config.SegmentedControl.backdropStyle,
        background: config.colors.twitter,
      }}
    />
  );
}
