import { Input } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import useRandomItem from "hooks/useRandomItem";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce";
import { pluralize } from "utils/pluralize";
import urlRegex from "utils/urlRegex";
import * as Yup from "yup";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const YouTubeChannelSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();

  const [channelURL, setChannelURL, channelURLError] = useInputValidator(
    source.channel_url,
    Yup.string().matches(urlRegex, "Please enter a valid URL"),
    (url) => updateChannelURL(sourceIndex, url)
  );

  const [updateChannelURL, channelURLLoading, channelURLApiError] = useUpdateChannelURL();

  const feedURLPlaceholder = useRandomItem(placeholders);

  const handleChannelURLFieldSubmit = () => {
    channelURL?.length && updateChannelURL(sourceIndex, channelURL, dispatch);
  };

  return (
    <SourceEditorWrapper>
      {/* CHANNEL URL */}
      <SourceEditorSection
        title="YOUTUBE CHANNEL URL"
        subtitle="Enter the URL of the YouTube Channel, we will figure out the rest."
        icon="link"
        noBorderTop
      >
        <Input
          value={channelURL}
          onChange={(e) => setChannelURL(e.target.value)}
          onSubmit={handleChannelURLFieldSubmit}
          loading={channelURLLoading}
          type="text"
          name="url"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder={feedURLPlaceholder}
          error={channelURLApiError || channelURLError}
          autoFocus={!(source?.title?.length > 0)}
        />
      </SourceEditorSection>

      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        title={`Include ${source.max_videos} most recent ${pluralize("video", source.max_videos)}`}
        source={source}
        sourceIndex={sourceIndex}
        fieldName="max_videos"
        min={1}
        max={20}
      />
      <SourceStyleOptionsWrapper>
        <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

const placeholders = [
  "https://www.youtube.com/user/marquesbrownlee",
  "https://www.youtube.com/user/TheVerge",
  "https://www.youtube.com/user/Nerdwriter1",
  "https://www.youtube.com/user/CGPGrey",
];

function useUpdateChannelURL() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [debouncedCallback] = useDebouncedCallback((sourceIndex, url) => {
    setLoading(true);
    setError(null);

    api
      .get("youtube_channel_info_from_url/", { params: { url } })
      .then((res) => {
        dispatch(updateSourceField(sourceIndex, "title", res.data.title));
        // prettier-ignore
        dispatch(updateSourceField(sourceIndex, "channel_id", res.data.channel_id));
        dispatch(updateSourceField(sourceIndex, "channel_url", url));
      })
      .catch((err) => setError(err.response?.data?.detail || "Can't get channel"))
      .finally(() => setLoading(false));
  }, 500);

  return [debouncedCallback, loading, error];
}

export default YouTubeChannelSourceEditor;
