import { Button, DropDownMenu, HStack, P1, Spacer, useConfig } from "@mailbrew/uikit";
import SettingsPageWrapper from "components/SettingsPageWrapper";
import api from "dependencies/api";
import loggedInPage from "hoc/loggedInPage";
import { useDispatch } from "react-redux";
import useSWR, { mutate } from "swr";
import ServiceCard from "../../components/ServiceCard";
import { removeTwitterAccount } from "../../reducers/settingsReducer";
import { connectTwitterAccount, openAuthWindow } from "../../utils/twitterAuth";

const services = [
  { id: "twitter", name: "Twitter", icon: "twitter" },
  { id: "google", name: "Google Calendar", icon: "google" },
];

const AccountsSettingsPage = () => {
  const dispatch = useDispatch();

  const config = useConfig();
  const { c4 } = config.colors;

  const { data: accounts } = useSWR("/user_accounts/");

  const onAddService = async (service) => {
    if (service === "twitter") {
      connectTwitterAccount();
    }
    if (service === "google") {
      connectGoogleAccount();
    }
  };

  const onRemoveGoogleAccount = async (google_id) => {
    await api.post(`/connect/google/delete/${google_id}/`);
    mutate("/user_accounts/");
  };

  return (
    <SettingsPageWrapper>
      {accounts && accounts.length === 0 && (
        <P1 align="center" color={c4}>
          Connect your first account.
        </P1>
      )}
      {accounts &&
        accounts.map((account, index) => {
          switch (account.type) {
            case "twitter":
              return (
                <ServiceCard
                  type={"twitter"}
                  user={account.screen_name}
                  connected={!account.error}
                  onClickReconnect={connectTwitterAccount}
                  onClickRemove={async () => {
                    await dispatch(removeTwitterAccount());
                  }}
                />
              );
            case "google":
              return (
                <ServiceCard
                  type={"google"}
                  user={account.email}
                  connected={!account.error}
                  onClickReconnect={connectGoogleAccount}
                  onClickRemove={() => {
                    onRemoveGoogleAccount(account.google_id);
                  }}
                />
              );
            default:
              throw new Error("unsupported account type");
          }
        })}
      <Spacer size="xl" />
      <HStack align="right" vAlign="center" noWrap>
        <DropDownMenu
          options={services.map((i) => i.id)}
          optionsNames={services.map((i) => i.name)}
          optionsIcons={services.map((i) => i.icon)}
          onSelect={onAddService}
        >
          <Button icon="link" stopPropagation={false}>
            Connect Service
          </Button>
        </DropDownMenu>
      </HStack>
    </SettingsPageWrapper>
  );
};

export async function connectGoogleAccount() {
  openAuthWindow("/connect/google/start/", () => {
    mutate("/user_accounts/");
  });
}

export default loggedInPage(AccountsSettingsPage);
