import { usePaywallState } from "components/PaywallStateProvider";
import { useSelector } from "react-redux";
import { currentNewsletterSelector } from "reducers/newslettersReducer";

export default function useGamificationProgress() {
  const { afterOnboarding } = usePaywallState();
  const brew = useSelector(currentNewsletterSelector);
  const progress = brew?.sources?.length ?? 0;
  return afterOnboarding ? progress : null;
}
