import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const ProductHuntSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <SourceEditorWrapper>
      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        source={source}
        noBorderTop
        sourceIndex={sourceIndex}
        min={1}
        max={10}
        fieldName="num_posts"
        explanationModalBody="In each issue of your digest, Mailbrew includes the top products from Product Hunt published since the previous issue of your digest."
        shortExplanationBuilder={(numPosts) =>
          numPosts > 1 ? `Receive the ${numPosts} most popular products.` : `Receive the most popular product.`
        }
      />

      <SourceStyleOptionsWrapper>
        <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default ProductHuntSourceEditor;
