import { Input, Spacer, Stack, Switch, Tooltip } from "@mailbrew/uikit";
import useCheckCanUseAdvancedLayout from "hooks/useCheckCanUseAdvancedLayout";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce/lib";
import SourceEditorSection from "./editor/SourceEditorSection";

export default function SourceTitleEditorSection({ source, sourceIndex }) {
  const dispatch = useDispatch();
  const checkCanUse = useCheckCanUseAdvancedLayout();

  const [debouncedUpdateTitle] = useDebouncedCallback(
    (title) => {
      dispatch(updateSourceField(sourceIndex, "title", title));
    },
    500,
    {
      leading: true,
    }
  );

  const [title, setTitle] = useInputValidator(
    source.title ?? "",
    () => null,
    (title) => debouncedUpdateTitle(title)
  );

  if (!source) return null;

  const showTitle = source.show_title ?? true;

  const toggleShowTitle = () => checkCanUse(() => dispatch(updateSourceField(sourceIndex, "show_title", !showTitle)));

  return (
    <>
      <Spacer size={2} />
      <SourceEditorSection icon="writeBold" noBorders noLateralPadding>
        <Stack noWrap>
          <Input
            icon="writeBold"
            placeholder="Custom title..."
            type="text"
            autoComplete="off"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Tooltip title="Show or hide this source title">
            <Switch on={showTitle} onClick={toggleShowTitle} />
          </Tooltip>
        </Stack>
      </SourceEditorSection>
    </>
  );
}
