import { Grid, Input, RadioGroup, SmallText } from "@mailbrew/uikit";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxTweetsSection from "components/MaxTweetsSection";
import { PillButton } from "components/PillButton";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import useInputValidator from "hooks/useInputValidator";
import useRandomItem from "hooks/useRandomItem";
import debounce from "just-debounce-it";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import supportLinks from "supportLinks";
import { notifySourcePreviewForReload } from "utils/cutom-events";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const minPosts = 1;
const maxPosts = 100;

const TwitterSearchSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);

  // we use the input validator just to decouple updating the value while typing and updating it in the state
  // (e.g.triggering a re-render of the preview). We do this to avoid being rate-limited by Twitter Search API.
  const [query, setQuery] = useInputValidator(
    source.query,
    () => null, // always valid
    (value) => updateQueryDebounced(dispatch, sourceIndex, value)
  );

  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");

  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const queryPlaceholder = useRandomItem([
    "mailbrew OR mailbrew.com",
    "from:jack -filter:retweets -filter:replies",
    "from:viticci filter:links",
    "from:verge OR from:techcrunch",
  ]);

  const queryQuickActions = [
    ["No RT", "retweet", "-filter:retweets"],
    ["No Replies", "bubbleBold", "-filter:replies"],
    ["Just Links", "link", "filter:links"],
    ["Just Images", "pictureBold", "filter:images"],
  ];

  const handleConnectedTwitterAccountChange = () => {
    notifySourcePreviewForReload(sourceIndex);
  };

  return (
    <SourceEditorWrapper>
      {!twitterAccountConnected && (
        <SourceEditorSection noBorderTop icon="twitter" title="Connected Twitter Account">
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        {/* QUERY */}
        <SourceEditorSection title="Search Query" icon="search" noBorderTop>
          <Input
            name="query"
            placeholder={queryPlaceholder}
            type="text"
            autoComplete="off"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Grid columns="1fr 1fr" mt={3} gap={0.3} w="100%" style={{ justifyItems: "stretch" }} noWrap>
            {queryQuickActions.map((quickAction, index) => {
              const [title, icon, query] = quickAction;
              return (
                <>
                  <PillButton
                    stretch
                    key={index}
                    icon={icon}
                    fontSize="13.5px"
                    mb={0.6}
                    onClick={() => dispatch(updateSourceField(sourceIndex, "query", source.query + " " + query))}
                  >
                    {title}
                  </PillButton>
                </>
              );
            })}
          </Grid>
          <SmallText mt={2}>
            You can use the full{" "}
            <a target="_blank" rel="noopener noreferrer" href={supportLinks.twitterSearch}>
              Twitter Search Syntax
            </a>
            .
          </SmallText>
        </SourceEditorSection>

        {/* ORDERING */}
        <SourceEditorSection title="Ordering" icon="select">
          <RadioGroup
            options={["mixed", "recent"]}
            optionsNames={["Popular Tweets First", "Recent Tweets First"]}
            selectedOption={source.ordering}
            onSelect={(ordering) => {
              dispatch(updateSourceField(sourceIndex, "ordering", ordering));
            }}
          />
        </SourceEditorSection>

        {/* MAX NUMBER OF TWEETS */}
        <MaxTweetsSection
          source={source}
          sourceIndex={sourceIndex}
          min={minPosts}
          max={maxPosts}
          infoMessage={(value) =>
            `We'll send you up to ${value} tweets matching your query that have been tweeted since the previous issue of the newsletter.`
          }
        />

        <SourceStyleOptionsWrapper>
          <SourceOptionGrid>
            <ImagesStyleSetting
              style={source.images_style}
              onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
              pb={0}
              pt={0}
            />
            <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
          </SourceOptionGrid>
          <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
          <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
        </SourceStyleOptionsWrapper>
      </Disable>

      {twitterAccountConnected && (
        <SourceEditorSection noPadding noBorderBottom>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

const updateQueryDebounced = debounce((dispatch, sourceIndex, query) => {
  dispatch(updateSourceField(sourceIndex, "query", query));
}, 1000);

export default TwitterSearchSourceEditor;
