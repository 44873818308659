import { P2 } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ExternalLink from "components/ExternalLink";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { pluralize } from "utils/pluralize";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const BetaListSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <SourceEditorWrapper>
      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        title={`Include ${source.num_posts} popular ${pluralize("beta", source.num_posts)}`}
        source={source}
        noBorderTop
        noBorderBottom
        sourceIndex={sourceIndex}
        min={1}
        max={10}
        fieldName="num_posts"
      />

      <SourceEditorSection noBorderTop>
        <P2>
          Powered by <ExternalLink href="https://betalist.com/?ref=mailbrew">BetaList</ExternalLink>.
        </P2>
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default BetaListSourceEditor;
