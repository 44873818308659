import { useBreakpoint } from "@mailbrew/uikit";
import CompactSelectSetting from "components/CompactSelectSetting";
import useCheckCanUseAdvancedLayout from "hooks/useCheckCanUseAdvancedLayout";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { BREW_BREAKPOINT } from "./BrewEditor";

export default function NumColumnsSourceEditor({ source, sourceIndex }) {
  const dispatch = useDispatch();
  const isMobileBrew = useBreakpoint(BREW_BREAKPOINT);
  const options = [1, 2, 3];
  const optionsNames = ["1 Column", "2 Columns", "3 Columns"];

  const checkCanUse = useCheckCanUseAdvancedLayout();

  function handleChange(option) {
    checkCanUse(() => {
      dispatch(updateSourceField(sourceIndex, "columns", option));
      if (option === 1) {
        dispatch(updateSourceField(sourceIndex, "width", "compact"));
      }
      if (option === 2) {
        dispatch(updateSourceField(sourceIndex, "width", "wide"));
      }
      if (option === 3) {
        dispatch(updateSourceField(sourceIndex, "width", "ultra_wide"));
      }
    });
  }

  if (isMobileBrew) return null;

  return (
    <>
      <CompactSelectSetting
        label="Layout"
        icon="layoutBold"
        selectedOption={source.columns || 1}
        onChange={handleChange}
        optionsIds={options}
        optionsNames={optionsNames}
      />
    </>
  );
}
