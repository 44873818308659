import {
  Box,
  Center,
  Icon,
  Modal,
  notif,
  P1,
  P2,
  SmallText,
  Spacer,
  Spinner,
  Stack,
  Tooltip,
  useBreakpoint,
  useConfig,
  useDebounce,
  useDetectBrowser,
} from "@mailbrew/uikit";
import SourceIcon from "components/SourceIcon";
import SourcesShortcuts, { getShortcutType, MODE_ADD_SOURCE } from "components/SourcesShortcuts";
import { sourcesData } from "data/sourcesData";
import { motion } from "framer-motion";
import useDelayedValue from "hooks/useDelayedValue";
import useEventListener from "hooks/useEventListener";
import useGamificationProgress from "hooks/useGamificationProgress";
import useRandomItem from "hooks/useRandomItem";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Script from "react-load-script";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, userTwitterAccountSelector } from "reducers/authReducer";
import { addSource, removeSource, setSourceBeingEditedIndex, updateSourceField } from "reducers/newslettersReducer";
import plausible from "utils/plausible";
import scrollToSourceById from "utils/scrollToSourceById";
import { sourcesModalShortcuts } from "utils/sources";
import makeSourceTemplate from "utils/sourcesMakeTemplate";
import urlRegex from "utils/urlRegex";
import { v4 as uuidv4 } from "uuid";
import AddSourceBoxSubtle from "./AddSourceBoxSubtle";
import { makeStockFromSuggestion } from "./sources/StocksSourceEditor";
import { makePodcastFromSuggestion } from "./sources/PodcastSourceEditor";

const AddSourceModal = ({ show, setShow, addSourceIndex }) => {
  const config = useConfig();
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const hit = useBreakpoint(480);
  const { hasTouch } = useDetectBrowser();

  const twitterAccount = useSelector(userTwitterAccountSelector);
  const hasTwitterAccount = twitterAccount && !twitterAccount.error;

  const [searchQuery, setSearchQuery] = useState("");
  const [activeSourceSearchQuery, setActiveSourceSearchQuery] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const searchResults = useMemo(() => searchSources(sourcesModalShortcuts, searchQuery.trim()), [searchQuery]);
  const isSearching = searchQuery.length > 1;
  const shownSources = isSearching ? [{ content: searchResults }] : sourcesModalShortcuts;
  const inputRef = useRef();

  const [activeSource, setActiveSource] = useState(null);
  const [activeSourceSuggestions, setActiveSourceSuggestions] = useState(null);

  function resetModal() {
    setSearchQuery("");
    setActiveSourceSearchQuery("");
    setActiveSource(null);
    setActiveSourceSuggestions(null);
  }

  useEffect(() => {
    if (debouncedSearchQuery?.length > 2) {
      plausible.track("Search in source modal", { query: debouncedSearchQuery });
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    if (!show && activeSource) {
      dispatch(removeSource(addSourceIndex));
      dispatch(setSourceBeingEditedIndex(null));
      resetModal();
    } else if (!show && !activeSource) {
      resetModal();
    }
  }, [activeSource, addSourceIndex, dispatch, show]);

  function handleScriptLoaded() {
    const { nolt } = window;
    nolt("init", { selector: "#nolt-button-sources", url: "https://mailbrew.nolt.io" });
    nolt("identify", { jwt: user.nolt_token });
  }

  const handleSourceAdded = useCallback(
    (source, shortcut, keepQuery) => {
      const sourceData = sourcesData[source.type];

      // scroll to source when closing the modal on mobile
      // (needed when software keyboard is up to show the source being edited)
      const scrollToSource = () => hasTouch && scrollToSourceById(source.id);

      if (source.type === "twitter_user" && !hasTwitterAccount) {
        setShow(false);
        scrollToSource();
      } else {
        if (sourceData.inlineSearch && !shortcut?.noInlineEditing) {
          setActiveSource(source);
          keepQuery && setActiveSourceSearchQuery(searchQuery);
        } else {
          setShow(false);
          scrollToSource();
        }
      }
    },
    [hasTouch, hasTwitterAccount, searchQuery, setShow]
  );

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setShow(false);
    }
  };

  const addSourceByType = useCallback(
    (type, index) => {
      setSearchQuery("");
      const source = makeSourceTemplate(type);
      dispatch(addSource({ source, index }));
      dispatch(setSourceBeingEditedIndex(index));
      handleSourceAdded(source);
    },
    [dispatch, handleSourceAdded]
  );

  const uiState = (() => {
    if (activeSource) return "editing_source";
    if (isSearching) {
      if (searchResults.length === 0) {
        return "no_results";
      } else {
        return "searching";
      }
    } else {
      return "default";
    }
  })();

  // Find patterns in search query and add the right source
  useEffect(() => {
    const trimmedSearchQuery = searchQuery.trim();
    if (uiState !== "editing_source") {
      // Type a subreddit
      if (trimmedSearchQuery.indexOf("r/") === 0) {
        addSourceByType("subreddit", addSourceIndex);

        // Type a Twitter user
      } else if (hasTwitterAccount && trimmedSearchQuery.indexOf("@") === 0) {
        addSourceByType("twitter_user", addSourceIndex);

        // Type or paste a URL
      } else if (trimmedSearchQuery.match(urlRegex)) {
        // YouTube
        if (getYoutubeUserFromUrl(trimmedSearchQuery)) {
          setActiveSourceSearchQuery(getYoutubeUserFromUrl(trimmedSearchQuery));
          addSourceByType("youtube_playlist", addSourceIndex);

          // Subreddit
        } else if (getSubredditFromUrl(trimmedSearchQuery)) {
          setActiveSourceSearchQuery(getSubredditFromUrl(trimmedSearchQuery));
          addSourceByType("subreddit", addSourceIndex);

          // Twitter
        } else if (hasTwitterAccount && getTwitterUserFromUrl(trimmedSearchQuery)) {
          setActiveSourceSearchQuery(getTwitterUserFromUrl(trimmedSearchQuery));
          addSourceByType("twitter_user", addSourceIndex);

          // Other (RSS)
        } else {
          setActiveSourceSearchQuery(trimmedSearchQuery);
          addSourceByType("rss_feed", addSourceIndex);
        }
      }
    }
    // Careful changing these dependencies because this code adds a new source
  }, [addSourceByType, addSourceIndex, hasTwitterAccount, searchQuery, uiState]);

  function handleSuggestionSelected(suggestion) {
    if (activeSource && suggestion) {
      if (activeSource.type === "stocks") {
        dispatch(updateSourceField(addSourceIndex, "stocks", [makeStockFromSuggestion(suggestion.payload)]));
      } else if (activeSource.type === "rss_feed") {
        const { feed_url, title } = suggestion.payload;
        dispatch(updateSourceField(addSourceIndex, "url", feed_url));
        dispatch(updateSourceField(addSourceIndex, "title", title));
      } else if (activeSource.type === "youtube_playlist") {
        const { value, name } = suggestion;
        dispatch(updateSourceField(addSourceIndex, "title", name));
        dispatch(updateSourceField(addSourceIndex, "playlist_id", value));
      } else if (activeSource.type === "twitter_user") {
        dispatch(updateSourceField(addSourceIndex, "screen_name", suggestion.value));
      } else if (activeSource.type === "subreddit") {
        dispatch(updateSourceField(addSourceIndex, "subreddit", suggestion.value));
      } else if (activeSource.type === "podcast") {
        dispatch(updateSourceField(addSourceIndex, "podcasts", [makePodcastFromSuggestion(suggestion.payload)]));
      } else if (activeSource.type === "weather") {
        dispatch(updateSourceField(addSourceIndex, "location_key", suggestion.payload.location_key));
        dispatch(updateSourceField(addSourceIndex, "location_name", suggestion.payload.title));
      }

      resetModal();

      setShow(false);

      scrollToSourceById(activeSource.id);
    }
  }

  function handleBackFromActiveSource() {
    if (activeSource) {
      dispatch(removeSource(addSourceIndex));
      dispatch(setSourceBeingEditedIndex(null));
    }
    resetModal();
  }

  return (
    <Modal
      bg={config.colors.bg0}
      width="620px"
      radius={3}
      p={0}
      hideCloseButton
      show={show}
      setShow={setShow}
      inline
      overflow="hidden"
      lockScrolling
      onKeyDown={handleKeyDown}
      bottomSheet={hit}
    >
      {show && <Script url={"https://cdn.nolt.io/widgets.js"} onLoad={handleScriptLoaded} />}

      {uiState !== "editing_source" && (
        <MainSearchField
          inputRef={inputRef}
          key="searching"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setShow={setShow}
        />
      )}

      {uiState === "editing_source" && (
        <ActiveSourceSearchField
          key="editing-source"
          searchQuery={activeSourceSearchQuery}
          setSearchQuery={setActiveSourceSearchQuery}
          source={activeSource}
          setShow={setShow}
          onBackClick={handleBackFromActiveSource}
          setSourceSuggestions={setActiveSourceSuggestions}
        />
      )}

      <div
        style={{
          height: "60vh",
          maxHeight: "540px",
          overflow: "scroll",
          display: "flex",
        }}
      >
        <Box p={uiState === "editing_source" ? 2 : 4} flex fd="column" ai="stretch" minHeight="100%" w="100%">
          {(uiState === "default" || uiState === "searching") && (
            <SourcesShortcuts
              shortcuts={shownSources}
              mode={MODE_ADD_SOURCE}
              shortcutInsertionIndex={addSourceIndex}
              onSourceAdded={handleSourceAdded}
            />
          )}
          {uiState === "no_results" && (
            <Box
              flex
              fd="column"
              ai="stretch"
              jc={hit ? "flex-start" : "center"}
              minHeight="100%"
              w="460px"
              maxW="100%"
              mx="auto"
              pb={14}
            >
              <P2 mb={1} align="center" color={config.colors.c3}>
                No sources found...
              </P2>
              <SmallText mb={4} align="center" color={config.colors.c4}>
                Try pasting a URL, or use the filters below:
              </SmallText>
              <SourcesShortcuts
                shortcuts={hasTwitterAccount ? noResultsShortcutsWithTwitter : noResultsShortcuts}
                mode={MODE_ADD_SOURCE}
                shortcutInsertionIndex={addSourceIndex}
                onSourceAdded={(source, shortcut) => handleSourceAdded(source, shortcut, true)}
                customSourceComponent={AddSourceBoxSubtle}
                cellWidth="40px"
                fill={false}
              />
            </Box>
          )}
          {uiState === "editing_source" && (
            <SourceSuggestions
              showNoResults={activeSourceSearchQuery?.length > 1 && !activeSourceSuggestions}
              suggestions={activeSourceSuggestions}
              onSuggestionSelected={handleSuggestionSelected}
            />
          )}
          <Spacer size={4} />
        </Box>
      </div>
    </Modal>
  );
};

const MainSearchField = ({ searchQuery, setSearchQuery, setShow, inputRef }) => {
  const config = useConfig();
  const { hasTouch } = useDetectBrowser();
  const gamificationProgress = useGamificationProgress();

  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipAvailable = useMemo(() => gamificationProgress === 2, [gamificationProgress]);

  useEffect(() => {
    if (tooltipAvailable) {
      const timeout = setTimeout(() => {
        setShowTooltip(true);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [tooltipAvailable]);

  useEffect(() => {
    if (searchQuery && showTooltip) {
      setShowTooltip(false);
    }
  }, [searchQuery, showTooltip]);

  return (
    <Stack
      gap={2.5}
      noWrap
      key="editing"
      bg={config.colors.bg1}
      p={4}
      borderBottom={`1px solid ${config.colors.uiBorderColor}`}
    >
      <Icon name="plus" size="20px" color={config.colors.c4} />
      <Tooltip
        open={showTooltip}
        title={hasTouch ? "💡 You can type any link here" : "💡 Try pasting a website or blog link here"}
        position="top"
        maxWidth="auto"
        arrow
        offset={60}
      >
        <input
          data-hj-allow
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search or paste a link..."
          value={searchQuery}
          autoFocus={!hasTouch}
          autoCorrect={false}
          autoComplete={false}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setShow(false);
            }
          }}
          style={{
            flex: "999 1 auto",
            border: "none",
            appearance: "none",
            fontSize: "20px",
            background: "transparent",
            padding: 0,
            outline: "none",
            color: config.colors.c1,
            fontWeight: 400,
          }}
          ref={inputRef}
        />
      </Tooltip>
    </Stack>
  );
};

const ActiveSourceSearchField = ({ searchQuery, setSearchQuery, source, onBackClick, setSourceSuggestions }) => {
  const sourceData = sourcesData[source.type];
  const { fetcher, placeholders } = sourceData.inlineSearch;

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsNames, setSuggestionsNames] = useState([]);
  const [suggestionsDetails, setSuggestionsDetails] = useState([]);
  const [suggestionsIcons, setSuggestionsIcons] = useState([]);
  const [suggestionsImages, setSuggestionsImages] = useState([]);
  const [suggestionsPayloads, setSuggestionsPayloads] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sourceSuggestions = useMemo(
    () =>
      suggestions.map((value, index) => ({
        value,
        name: suggestionsNames[index],
        detail: suggestionsDetails[index],
        icon: suggestionsIcons[index],
        image: suggestionsImages[index],
        payload: suggestionsPayloads[index],
      })),
    [suggestions, suggestionsDetails, suggestionsIcons, suggestionsImages, suggestionsNames, suggestionsPayloads]
  );

  useEffect(() => {
    if (sourceSuggestions?.length > 0) {
      setSourceSuggestions(sourceSuggestions);
    } else {
      setSourceSuggestions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSuggestions]);

  useEffect(() => {
    if (error) {
      notif.error(error);
    }
  }, [error]);

  const handleFetchSuggestions = useCallback(
    (e) => {
      setSearchQuery(e.target.value);
      fetcher(e.target.value, {
        setSuggestions,
        setSuggestionsDetails,
        setSuggestionsNames,
        setSuggestionsIcons,
        setSuggestionsImages,
        setSuggestionsPayloads,
        setError,
        setLoading,
      });
    },
    [fetcher, setSearchQuery]
  );

  useEffect(() => {
    if (searchQuery) {
      handleFetchSuggestions({ target: { value: searchQuery } });
    }
  }, [handleFetchSuggestions, searchQuery]);

  const config = useConfig();

  const placeholder = useRandomItem(placeholders);

  return (
    <Stack
      gap={2.5}
      noWrap
      key="editing"
      bg={config.colors.bg1}
      p={4}
      borderBottom={`1px solid ${config.colors.uiBorderColor}`}
    >
      <Stack
        noWrap
        gap={0.5}
        ml={-1}
        onClick={onBackClick}
        style={{ cursor: "pointer" }}
        position="relative"
        bottom="-1px"
      >
        <Icon name="chevronLeft" color={config.colors.c4} />
        <SourceIcon width="20px" height="20px" type={source.type} />
      </Stack>
      <Stack noWrap w="100%">
        <input
          data-hj-allow
          autoFocus
          value={searchQuery}
          onChange={handleFetchSuggestions}
          type="text"
          autoCorrect={false}
          autoComplete={false}
          placeholder={placeholder}
          style={{
            flex: "999 1 auto",
            width: "100%",
            border: "none",
            appearance: "none",
            fontSize: "20px",
            background: "transparent",
            padding: 0,
            outline: "none",
            color: config.colors.c1,
            fontWeight: 400,
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              onBackClick && onBackClick();
            }
            if (!e.target.value && e.key === "Backspace") {
              onBackClick && onBackClick();
            }
          }}
        />
        {loading && <Spinner size={18} mr={1} />}
      </Stack>
    </Stack>
  );
};

const SourceSuggestions = ({ suggestions, onSuggestionSelected, showNoResults }) => {
  const config = useConfig();
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const delayedShowNoResults = useDelayedValue(showNoResults, 4000, true);

  function prevIndex() {
    setHighlightedIndex(highlightedIndex === 0 ? highlightedIndex === suggestions.length - 1 : highlightedIndex - 1);
  }

  function nextIndex() {
    setHighlightedIndex(highlightedIndex === suggestions.length - 1 ? 0 : highlightedIndex + 1);
  }

  useEventListener("keydown", (e) => {
    if (e.key === "ArrowDown") {
      nextIndex();
    }
    if (e.key === "ArrowUp") {
      prevIndex();
    }
    if (e.key === "Enter") {
      onSuggestionSelected && onSuggestionSelected(suggestions?.[highlightedIndex]);
    }
  });

  if (suggestions?.length > 0)
    return (
      <Stack vertical w="100%" align="stretch" gap={1}>
        {suggestions.map((suggestion, index) => (
          <SourceSuggestion
            key={suggestion.value}
            index={index}
            suggestion={suggestion}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            onClick={onSuggestionSelected}
          />
        ))}
      </Stack>
    );

  if (delayedShowNoResults) {
    return (
      <Center
        as={motion.div}
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", duration: 0.5, bounce: 0.1 }}
        h="100%"
        style={{ flex: 1 }}
      >
        <SmallText color={config.colors.c4} align="center">
          No results...
        </SmallText>
      </Center>
    );
  }

  return null;
};

const SourceSuggestion = ({ suggestion, index, highlightedIndex, setHighlightedIndex, onClick }) => {
  const { value, name, detail, icon, image } = suggestion;
  const config = useConfig();

  const isHighlighted = index === highlightedIndex;

  return (
    <Stack
      onMouseEnter={() => setHighlightedIndex(index)}
      align="stretch"
      w="100%"
      gap={0}
      noWrap
      overflow="hidden"
      style={{ cursor: "pointer" }}
      onClick={() => onClick(suggestion)}
      px={2}
      py={1}
      radius={1.5}
      background={isHighlighted ? config.colors.bg4 : "transparent"}
    >
      <Stack vertical gap={0} overflow="hidden" style={{ flex: 1 }}>
        <P1 noWrap weight="500">
          {name ?? value ?? ""}
        </P1>
        {detail && (
          <SmallText color={config.colors.c4} mt={0} noWrap>
            {detail}
          </SmallText>
        )}
      </Stack>
      {icon && <Icon style={{ flex: "0 0 34px" }} size="34px" color={config.colors.c3} name={icon} ml="8px" />}
      {image && (
        <img
          width={34}
          height={34}
          src={image}
          alt=""
          style={{
            flex: `0 0 34px`,
            borderRadius: 4,
            marginLeft: "8px",
            objectFit: "contain",
          }}
        />
      )}
    </Stack>
  );
};

function searchSources(shortcuts, searchQuery) {
  const content = shortcuts.map((group) => group.content).reduce((prev, curr) => prev.concat(curr), []);

  // make searchable string with matching indexes to content
  const searchableContent = content.map((shortcut) => {
    const t = getShortcutType(shortcut);
    switch (t) {
      case "simple_source":
        const data = sourcesData[shortcut];
        return `${data.name} ${data.keywords}`.toLowerCase();
      case "full_source":
        return `${shortcut.title} ${shortcut.source.title}`.toLowerCase();
      case "flow":
        return `${shortcut.title} ${shortcut.id}`.toLowerCase();
      default:
        return "";
    }
  });

  const matches = [];
  const query = searchQuery.toLowerCase();

  searchableContent.forEach((item, index) => {
    if (item.includes(query)) {
      matches.push(content[index]);
    }
  });

  return matches;
}

function getSubredditFromUrl(url) {
  if (!url) return null;

  const regex = /^https?:\/\/(?:www\.)?reddit\.com\/r\/([\w-]+)\/?/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  } else {
    return "";
  }
}

function getTwitterUserFromUrl(url) {
  if (!url) return null;

  const regex = /^https?:\/\/twitter\.com\/([\w-]+)\/?/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  } else {
    return "";
  }
}

function getYoutubeUserFromUrl(url) {
  if (!url) return null;

  const regex = /^https?:\/\/www\.youtube\.com\/(?:channel\/|user\/)([\w-]+)\/?/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  } else {
    return "";
  }
}

const renamedRss = {
  kind: "source",
  title: "RSS",
  id: "rss",
  source: {
    id: uuidv4(),
    type: "rss_feed",
  },
};

const renamedTwitterUser = {
  kind: "source",
  title: "Twitter user",
  id: "twitter_user",
  source: {
    id: uuidv4(),
    type: "twitter_user",
  },
};

const noResultsShortcutsWithTwitter = [
  {
    content: [renamedRss, renamedTwitterUser, "subreddit", "youtube_playlist", "stocks"],
  },
];
const noResultsShortcuts = [
  {
    content: [renamedRss, "subreddit", "youtube_playlist", "stocks"],
  },
];

export default AddSourceModal;
