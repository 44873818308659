import { useState } from "react";
import { P2, Spacer, useConfig } from "@mailbrew/uikit";
import InfoModal from "./InfoModal";

const InfoText = (props) => {
  const { text, label, modalTitle, modalBody, modalIcon, video, image } = props;

  const [modalShown, setModalShown] = useState(false);

  const config = useConfig();

  return (
    <>
      <Spacer size="xxs" />
      <P2>
        {text}{" "}
        {modalBody && (
          <span
            onClick={() => setModalShown(true)}
            style={{ color: config.colors.c1, borderBottom: `1px solid ${config.colors.c5}`, cursor: "pointer" }}
          >
            {label}
          </span>
        )}
      </P2>
      <InfoModal
        show={modalShown}
        setShow={setModalShown}
        icon={modalIcon}
        title={modalTitle}
        body={modalBody}
        video={video}
        image={image}
      />
    </>
  );
};

export default InfoText;
