import { Button, HStack, Icon, P2, Tooltip, useConfig } from "@mailbrew/uikit";
import TwitterSvg from "images/services/twitter.svg";
import GoogleSvg from "../images/services/google.svg";

const services = {
  google: {
    name: "Google",
    icon: GoogleSvg,
  },
  twitter: {
    name: "Twitter",
    icon: TwitterSvg,
  },
};

const ServiceCard = (props) => {
  const { type, user, connected, onClickRemove, onClickReconnect } = props;

  const userLabel = (() => {
    if (!user) return services[type].name;
    if (type === "twitter") return "@" + user;
    return user;
  })();

  const config = useConfig();

  const wrapperStyle = {
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    background: config.colors.bg0,
    color: config.colors.c3,
    borderRadius: config.appearance.smallRadius,
    userSelect: "none",
    padding: "7px",
    border: `1px solid ${config.colors.uiBorderColor}`,
    marginBottom: "0.6rem",
  };

  const ServiceIcon = services[type]?.icon ?? null;

  return (
    <div style={wrapperStyle}>
      <HStack align="spaced" noWrap overflow="hidden" maxW="100%">
        <HStack gap={1.5} noWrap style={{ flex: 1 }} overflow="hidden">
          <ServiceIcon
            alt={type}
            style={{
              width: "18px",
              height: "18px",
              marginLeft: "4px",
            }}
          />{" "}
          <P2 color={connected ? config.colors.c1 : config.colors.c3} noWrap>
            {userLabel}
          </P2>
          {!connected && user && (
            <Tooltip title="You need to re-connect this account.">
              <Icon size=".9em" style={{ marginBottom: "-.1em" }} name="warningBold" />
            </Tooltip>
          )}
        </HStack>
        <HStack noWrap align="right" style={{ flex: 0 }}>
          {!connected && !user && (
            <Button
              color={config.colors.accent2}
              variant={["secondary", "pill"]}
              icon="plusBold"
              onClick={onClickReconnect}
            >
              Connect New
            </Button>
          )}
          {!connected && user && (
            <Button variant={["secondary", "pill"]} icon="link" onClick={onClickReconnect}>
              Re-connect
            </Button>
          )}
          {user && (
            <Button variant={["secondary", "pill"]} icon="trashBold" onClick={onClickRemove}>
              Remove
            </Button>
          )}
        </HStack>
      </HStack>
    </div>
  );
};

export default ServiceCard;
