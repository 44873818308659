import { Button, notif, Stack, useConfig } from "@mailbrew/uikit";
import { BrewEditor } from "components/editor/BrewEditor";
import BrewInlineTitleEditor from "components/editor/BrewInlineTitleEditor";
import Page from "components/Page";
import { usePaywallState } from "components/PaywallStateProvider";
import { ScheduleEditor } from "components/ScheduleEditor";
import loggedInPage from "hoc/loggedInPage";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  brewSyncingSelector,
  currentNewsletterSelector,
  fetchNewsletter,
  setSourceBeingEditedIndex,
} from "reducers/newslettersReducer";
import urls from "urls";

function BrewEditorPage() {
  return (
    <EditorPageWrapper>
      <BrewInlineTitleEditor />
      <Stack mt={2} mb={6} align="center">
        <ScheduleEditor />
      </Stack>
      <BrewEditor />
    </EditorPageWrapper>
  );
}

export default loggedInPage(BrewEditorPage);

export function EditorPageWrapper({ children }) {
  const config = useConfig();
  const dispatch = useDispatch();
  const { afterOnboarding } = usePaywallState();
  const router = useRouter();
  const brewId = router.query.brewId;
  const syncing = useSelector(brewSyncingSelector);

  const brew = useSelector(currentNewsletterSelector);
  const showDone = afterOnboarding ? brew?.sources?.length >= 2 : true;

  useEffect(() => {
    if (brewId) dispatch(fetchNewsletter(brewId));
  }, [dispatch, brewId]);

  useSetSourceBeingEditedFromURL();

  if (!brew) {
    return <Page loading />;
  }

  return (
    <Page title={brew.untitled ? "Brew editor" : brew.title} noNavigation>
      <Stack align="right" pt={config.layout.padding} pr={config.layout.padding}>
        <EditorDoneButton style={{ opacity: showDone ? 1 : 0 }} loading={syncing} />
      </Stack>
      {children}
    </Page>
  );
}

function useSetSourceBeingEditedFromURL() {
  const dispatch = useDispatch();
  const router = useRouter();
  const brew = useSelector(currentNewsletterSelector);

  // handle navigating to source editor when opening links like: /edit/34/{source-id}
  useEffect(() => {
    const sourceID = router.query.args?.[0];
    if (brew && brew.sources && sourceID) {
      const index = brew.sources.findIndex((s) => s.id === sourceID);
      if (index !== -1) {
        dispatch(setSourceBeingEditedIndex(index));
        router.replace(urls.editNewsletter(brew.id));
      } else {
        notif.error("Source not found. You might have already removed it.", { duration: 10000 });
        router.replace(urls.editNewsletter(brew.id));
      }
    }
  }, [brew, dispatch, router]);
}

export function EditorDoneButton({ ...otherProps }) {
  const router = useRouter();
  const brew = useSelector(currentNewsletterSelector);

  function handleGoBack() {
    router.push(urls.brews() + `?brew=${brew.id}`, urls.brews());
  }

  return (
    <Button onClick={handleGoBack} icon="checkmark" variant="pill" {...otherProps}>
      Done
    </Button>
  );
}

export function useEditorSourceId() {
  const { query } = useRouter();
  const { args } = query;
  const sourceId = args?.[0];
  return sourceId;
}
