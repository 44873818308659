import { Button, Expandable, P2, useConfig } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorCallout from "components/editor/SourceEditorCallout";
import SourceEditorSection from "components/editor/SourceEditorSection";
import TitleEditor from "components/editor/TitleEditor";
import { usePaywallState } from "components/PaywallStateProvider";
import { ScheduleEditor } from "components/ScheduleEditor";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, syncNewsletter, updateNewsletterField } from "reducers/newslettersReducer";
import sendCustomEvent from "utils/cutom-events";

const BrewOptions = () => {
  const config = useConfig();
  const dispatch = useDispatch();

  const { afterOnboarding } = usePaywallState();

  const newsletter = useSelector(currentNewsletterSelector);
  const { sources } = newsletter;

  const hasSources = sources?.length > 0;

  return (
    <SourceEditorWrapper>
      <TitleEditor noBorderTop />

      <SourceEditorSection title={afterOnboarding ? "You'll get this brew:" : "Schedule"} icon="time" noBorderTop>
        <ScheduleEditor compact={afterOnboarding} key={newsletter.id} style={afterOnboarding ? { zoom: 1.1 } : {}} />
        {newsletter.paused && (
          <SourceEditorCallout color={config.colors.c1} icon="pauseBold" title="This brew is paused" mb={0}>
            <P2 color={config.colors.c2}>
              <Button fontSize="100%" variant="link" onClick={() => dispatch(updateNewsletterField("paused", false))}>
                Click here
              </Button>{" "}
              to reactivate it.
            </P2>
          </SourceEditorCallout>
        )}
      </SourceEditorSection>

      {hasSources && (
        <SourceEditorSection title="Options" icon="settingsAlt">
          <SettingSwitchRow
            state={newsletter.show_headings}
            onChange={() => {
              dispatch(updateNewsletterField("show_headings", !newsletter.show_headings));
              dispatch(
                syncNewsletter(() => {
                  sendCustomEvent("mailbrew:reload_all_sources");
                })
              );
            }}
            copy="Show source headings"
            icon="hamburger"
            color={config.colors.c3}
          />
          <Expandable expanded={newsletter.show_headings}>
            <SettingSwitchRow
              state={newsletter.show_source_icons ?? true}
              onChange={() => {
                dispatch(updateNewsletterField("show_source_icons", !newsletter.show_source_icons));
                dispatch(
                  syncNewsletter(() => {
                    sendCustomEvent("mailbrew:reload_all_sources");
                  })
                );
              }}
              copy="Show source icons"
              icon="dotsBold"
              color={config.colors.c3}
            />
          </Expandable>
          <SettingSwitchRow
            copy="Generate this brew"
            state={!newsletter.paused}
            onChange={() => dispatch(updateNewsletterField("paused", !newsletter.paused))}
            icon="playBold"
            color={config.colors.c3}
            tooltip="When disabled, no new issues will be created."
          />
          <SettingSwitchRow
            copy="Email me new issues"
            state={!newsletter.skip_send}
            onChange={() => dispatch(updateNewsletterField("skip_send", !newsletter.skip_send))}
            icon="emailBold"
            color={config.colors.c3}
            tooltip="When disabled, you can still read the brew online."
          />
          <SettingSwitchRow
            copy="Compatibility mode"
            state={newsletter.html_compatibility_mode}
            onChange={() =>
              dispatch(updateNewsletterField("html_compatibility_mode", !newsletter.html_compatibility_mode))
            }
            icon="code"
            color={config.colors.c3}
            tooltip="Enable this if your brews are broken in your email client. It will use older versions of our styles."
          />
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

export default BrewOptions;
