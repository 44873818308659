import { Box, Expandable, P2, SegmentedControl, useConfig } from "@mailbrew/uikit";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceEditorCallout from "components/editor/SourceEditorCallout";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxLinksSection from "components/MaxLinksSection";
import MaxTweetsSection from "components/MaxTweetsSection";
import SharesStyleSetting from "components/SharesStyleSetting";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import TweetsFilterSetting from "components/TweetsFilterSetting";
import TwitterListEditorSection from "components/TwitterListEditorSection";
import { Fragment, useEffect } from "react";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";
import { notifySourcePreviewForReload } from "utils/cutom-events";

const minTweets = 1;
const maxPosts = 200;

const minLinks = 1;
const maxLinksLimit = 15;

const TwitterLikesSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const config = useConfig();
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);

  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const { data: lists, error: listsApiError } = useSWR("twitter_lists/", { revalidateOnFocus: true });

  const onSelectTwitterList = (listId) => {
    const list = lists.find((l) => l.id === listId);
    dispatch(updateSourceField(sourceIndex, "list_id", list.id));
    dispatch(updateSourceField(sourceIndex, "ft_list_title", list.name));
  };

  const listIsTooBig = lists?.find((l) => l.id === source?.list_id)?.member_count > 25;

  // Allow columns only on "tweets" and "images" modes
  useEffect(() => {
    if (source.show === "links") {
      dispatch(updateSourceField(sourceIndex, "columns", 1));
    }
  }, [dispatch, source.columns, source.show, sourceIndex]);

  // Use good defaults for images style
  useEffect(() => {
    if (source.show === "tweets" || source.show === "images") {
      if (source.images_style === "wide_thumb") {
        dispatch(updateSourceField(sourceIndex, "images_style", "full"));
      }
    } else {
      dispatch(updateSourceField(sourceIndex, "images_style", "wide_thumb"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, source.show, sourceIndex]);

  const sourceIsValid = source.list_id;

  const handleConnectedTwitterAccountChange = () => {
    notifySourcePreviewForReload(sourceIndex);
  };

  return (
    <SourceEditorWrapper>
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        {/* LIST */}
        <SourceEditorSection noBorderTop pb={2}>
          <Expandable expanded={!source.list_id}>
            <P2 mb={3} color={config.colors.c2}>
              Discover the most popular content <strong>liked</strong> by any list.
            </P2>
          </Expandable>
          <TwitterListEditorSection
            source={source}
            lists={lists}
            error={listsApiError}
            onSelectTwitterList={onSelectTwitterList}
          />
          <Expandable mt={4} expanded={listIsTooBig}>
            <Box pb={2}>
              <SourceEditorCallout
                icon="people"
                title="Consider using a smaller list"
                body="This feature works best with lists under 25 users."
                mt={0}
                mb={0}
                color={"hsl(37, 100%, 40%)"}
              />
            </Box>
          </Expandable>
        </SourceEditorSection>

        {sourceIsValid && (
          <Fragment>
            <SourceEditorSection title="Display options" icon="content">
              {/* SHOW */}
              <SegmentedControl
                options={["tweets", "links"]}
                optionsNames={["Tweets", "Links"]}
                active={source.show}
                onOptionChange={(show) => {
                  dispatch(updateSourceField(sourceIndex, "show", show));
                }}
                backdropStyle={{
                  ...config.SegmentedControl.backdropStyle,
                  background: config.colors.twitter,
                }}
              />
              <P2 color={config.colors.c2} mt={3}>
                {source.show === "tweets" &&
                  "We'll pick the tweets that are most liked by the people on the chosen list."}
                {source.show === "links" &&
                  "We'll find the most popular links shared on Twitter and liked by the people on the chosen list."}
              </P2>
              {/* will pick the liked tweest | will extract articles from shared links in tweets | will show only tweets. */}
            </SourceEditorSection>

            {/* INCLUDE RETWEETS */}
            {/* <SettingSwitchRow
          state={source.include_rt}
          copy="Include Retweets"
          iconOn="retweet"
          iconOff="retweet"
          onChange={() => {
            dispatch(updateSourceField(sourceIndex, "include_rt", !source.include_rt));
          }}
          color={config.colors.c3}
        />
        <Spacer size="xl" /> */}

            {/* MAX NUMBER OF TWEETS */}
            {source.show === "tweets" && (
              <MaxTweetsSection source={source} sourceIndex={sourceIndex} min={minTweets} max={maxPosts} />
            )}
            {source.show === "links" && (
              <MaxLinksSection source={source} sourceIndex={sourceIndex} min={minLinks} max={maxLinksLimit} />
            )}

            <SourceEditorSection>
              {source.show !== "links" && (
                <TweetsFilterSetting
                  selected={source.tweet_filter ?? null}
                  onChange={(option) => dispatch(updateSourceField(sourceIndex, "tweet_filter", option))}
                />
              )}
              {source.show === "links" && <SharesStyleSetting source={source} sourceIndex={sourceIndex} />}
            </SourceEditorSection>
            <SourceStyleOptionsWrapper>
              <SourceOptionGrid>
                {source.tweet_filter !== "no_images" && (
                  <ImagesStyleSetting
                    label="Images Style"
                    style={source.images_style}
                    allowWideThumbnails={source.show === "links"}
                    onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
                  />
                )}
                {source.show !== "links" && <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />}
              </SourceOptionGrid>
              <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
              <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
            </SourceStyleOptionsWrapper>
          </Fragment>
        )}
      </Disable>

      {twitterAccountConnected && (
        <SourceEditorSection noPadding>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

export default TwitterLikesSourceEditor;
