import { mutate } from "swr";
import api from "../dependencies/api";
import { updateMe } from "../reducers/authReducer";
import store from "../store";

// NB: This is not a thunk/action because `window.open` needs to happen immediately in the click handler
// for the popup not to be blocked by Safari and other browsers.
export async function connectTwitterAccount(callback = () => {}) {
  openAuthWindow("/connect/twitter/start/", () => {
    store.dispatch(updateMe());
    mutate("/user_accounts/");
    callback();
  });
}

export async function openAuthWindow(backendGetRedirectPath, successCallback) {
  const authWindow = window.open(
    "about:blank",
    "_blank",
    "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600,left=100,top=100"
  );

  const res = await api.get(backendGetRedirectPath);
  const authURL = res.data.redirect;
  authWindow.location.href = authURL;

  // listen for "window.opener.postMessage" sent from backend via <script>
  window.addEventListener("message", (event) => {
    if (event?.data?.success) {
      successCallback && successCallback();
    }
  });
}

/**
 * Twitter signup
 */

// NB: This is not a thunk/action because `window.open` needs to happen immediately in the click handler
// for the popup not to be blocked by Safari and other browsers.
export async function signupWithTwitter(callback) {
  const authWindow = window.open(
    "about:blank",
    "_blank",
    "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600,left=100,top=100"
  );

  const res = await api.get("/signup/twitter/start/");
  const authURL = res.data.redirect;

  if (!authWindow) {
    alert("Your browser is blocking popups. Allow popups to connect Twitter or try signing up with email.");
    return;
  }

  authWindow.location.href = authURL;

  const signupId = res.data.signup_id;

  // listen for "window.opener.postMessage" sent from backend via <script>
  window.addEventListener("message", (event) => {
    if (event?.data?.success) {
      callback(signupId);
    }
  });
}
