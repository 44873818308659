import { AutoGrid, Box, Stack } from "@mailbrew/uikit";
import { useMonetizationFeatures } from "hooks/monetizationHooks";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSource, currentNewsletterSelector, setSourceBeingEditedIndex } from "reducers/newslettersReducer";
import urls from "urls";
import makeSourceTemplate from "utils/sourcesMakeTemplate";
import AddSourceBox from "./editor/AddSourceBox";
import { usePaywallState } from "./PaywallStateProvider";
import TitleDivider from "./TitleDivider";
import TwitterDigestModal from "./TwitterDigestModal";

export const [MODE_ADD_SOURCE, MODE_SUGGESTIONS] = [0, 1];

const SourcesShortcuts = ({
  shortcuts: sourceShortcuts,
  mode,
  shortcutInsertionIndex: _shortcutInsertionIndex,
  onSourceAdded,
  customSourceComponent,
  cellWidth = "100px",
  fill = true,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const AddSourceBoxComponent = customSourceComponent ?? AddSourceBox;

  const brew = useSelector(currentNewsletterSelector);
  const brewSources = brew?.sources ?? [];

  const [addedCustomShortcutsIds, setAddedCustomShortcutsIds] = useState([]);
  const { setPaywallModalShown } = usePaywallState();
  const { isSourceLocked } = useMonetizationFeatures();

  const [activeFlow, setActiveFlow] = useState(null);
  const shortcutInsertionIndex = _shortcutInsertionIndex ?? brewSources.length;

  const isShortcutAdded = (shortcut) => {
    switch (getShortcutType(shortcut)) {
      case "simple_source":
        return !!brewSources.find((s) => s.type === shortcut);
      case "full_source":
      case "flow":
        return !!addedCustomShortcutsIds.find((s) => s.id === shortcut.id);
      default:
        return false;
    }
  };

  function handleAddShortcut(shortcut) {
    const shortcutType = getShortcutType(shortcut);
    const srcType = shortcut?.source?.type ?? shortcut;

    const isLocked = (() => {
      switch (shortcutType) {
        case "simple_source":
          return isSourceLocked(shortcut);
        default:
          return false;
      }
    })();

    if (isLocked) {
      setPaywallModalShown(true);
      return;
    }

    // disallow adding the same suggestion multiple times
    // if (isShortcutAdded(shortcut) && mode === MODE_SUGGESTIONS) {
    //   notif.show("Edit the added source above.");
    //   return;
    // }

    let source;

    switch (shortcutType) {
      case "simple_source": {
        source = makeSourceTemplate(srcType);

        dispatch(addSource({ source, index: shortcutInsertionIndex }));
        dispatch(setSourceBeingEditedIndex(shortcutInsertionIndex));

        // open the source if it requires configuration
        // if (shortcutType === "simple_source" && srcData.opensEditor) {
        //   router.push(urls.editNewsletterSource(brew.id, source.id));
        // }

        onSourceAdded?.(source, shortcut);

        return;
      }

      case "full_source": {
        source = { ...makeSourceTemplate(shortcut.source.type), ...shortcut.source };
        setAddedCustomShortcutsIds((v) => [...v, source.id]);
        dispatch(addSource({ source, index: shortcutInsertionIndex }));
        dispatch(setSourceBeingEditedIndex(shortcutInsertionIndex));
        onSourceAdded?.(source, shortcut);
        return;
      }

      case "flow": {
        setActiveFlow(shortcut.id);
        return;
      }

      default:
        return;
    }
  }

  function handleFlowCompleted(flow, args) {
    if (flow === "twitter_digest") {
      const { source: twitterListSource } = args;
      dispatch(addSource({ source: twitterListSource, index: shortcutInsertionIndex }));
      setAddedCustomShortcutsIds((v) => [...v, "twitter_digest"]);
      dispatch(setSourceBeingEditedIndex(shortcutInsertionIndex));

      // We use this to show a "List created" sticky note in the Twitter List source editor https://cln.sh/4Irl6r
      if (window.location.pathname.startsWith("/edit")) {
        // editing in the editor: /edit/123
        router.push(urls.editNewsletterSource(brew.id, shortcutInsertionIndex) + "?help=digest");
      } else {
        // inline editing of the brew in the home page
        router.push("?help=digest");
      }

      onSourceAdded?.(twitterListSource, null);
    }
  }

  return (
    <>
      <Box>
        {sourceShortcuts.map((group, index) => {
          return (
            <Stack vertical key={index}>
              {group.title && <TitleDivider mt={index > 0 && 3.5} mb={2} title={group.title} icon={group.icon} small />}
              <AutoGrid cellWidth={cellWidth} fill={fill} gap={2} w="100%">
                {group.content.map((shortcut, index) => (
                  <Shortcut
                    key={index}
                    shortcut={shortcut}
                    checked={mode === MODE_SUGGESTIONS && isShortcutAdded(shortcut)}
                    onClick={() => handleAddShortcut(shortcut)}
                    component={AddSourceBoxComponent}
                  />
                ))}
              </AutoGrid>
            </Stack>
          );
        })}
      </Box>
      <TwitterDigestModal
        show={activeFlow === "twitter_digest"}
        setShow={(show) => !show && setActiveFlow(null)}
        onSourceCreated={(source) => handleFlowCompleted("twitter_digest", { source })}
      />
    </>
  );
};

const Shortcut = ({ component: Component, shortcut, checked, onClick }) => {
  let customTitle, sourceType, info;

  const t = getShortcutType(shortcut);

  switch (t) {
    case "simple_source":
      customTitle = null;
      sourceType = shortcut;
      break;
    case "full_source":
    case "flow":
      customTitle = shortcut.title;
      sourceType = t === "full_source" ? shortcut.source.type : shortcut.icon;
      info = shortcut.info;
      break;
    default:
      return;
  }

  return (
    <Component title={customTitle} sourceType={sourceType} info={info} onClick={onClick} checked={checked} small />
  );
};

export function getShortcutType(shortcut) {
  if (typeof shortcut === "string") {
    return "simple_source";
  } else if (typeof shortcut === "object" && shortcut.kind === "source") {
    return "full_source";
  } else if (typeof shortcut === "object" && shortcut.kind === "flow") {
    return "flow";
  }
}

export default SourcesShortcuts;
