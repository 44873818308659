import { usePaywallState } from "components/PaywallStateProvider";
import { useCallback } from "react";

export function useMonetizationFeatures() {
  const { monetization } = usePaywallState();

  const features = monetization.data.features;

  const unlockedSources: string[] = features.sources;

  const isSourceLocked = useCallback(
    (sourceType: string) => {
      return false;

      if (!unlockedSources) return true;
      return !unlockedSources.includes(sourceType);
    },
    [unlockedSources]
  );

  return {
    unlockedSources,
    isSourceLocked,
  };
}
