import { CheckboxGroup } from "@mailbrew/uikit";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import PostBodyOption from "components/editor/PostBodyOption";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { connect } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { pluralize } from "utils/pluralize";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const minPosts = 1;
const maxPosts = 30;

const HackerNewsSourceEditor = (props) => {
  const { source, sourceIndex, dispatch } = props;

  return (
    <SourceEditorWrapper>
      {/* NUMBER OF POSTS */}

      <NumPostsSourceEditor
        title={`Include ${source.num_posts} popular ${pluralize("post", source.num_posts)}`}
        source={source}
        sourceIndex={sourceIndex}
        fieldName="num_posts"
        min={minPosts}
        max={maxPosts}
        noBorderTop
      />

      {/* INCLUDE */}
      <SourceEditorSection title="Filter" icon="filter" collapsable>
        <CheckboxGroup
          options={["story", "show_hn", "ask_hn"]}
          optionsNames={["Stories", "Show HN", "Ask HN"]}
          selectedOptions={source.include}
          onSelect={(selectedOptions) => {
            dispatch(updateSourceField(sourceIndex, "include", selectedOptions));
          }}
        />
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceOptionGrid>
          <PostBodyOption source={source} sourceIndex={sourceIndex} hideFullOption />

          <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
        </SourceOptionGrid>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default connect((state) => ({
  newsletter: state.newsletters.currentNewsletter,
  loading: state.app.loading,
  errors: state.app.errors,
}))(HackerNewsSourceEditor);
