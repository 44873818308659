import BetaListSourceEditor from "components/editor/sources/BetaListSourceEditor";
import CalendarSourceEditor from "components/editor/sources/CalendarSourceEditor";
import CovidSourceEditor from "components/editor/sources/CovidSourceEditor";
import CryptoSourceEditor from "components/editor/sources/CryptoSourceEditor";
import DevToSourceEditor from "components/editor/sources/DevToSourceEditor";
import ExchangeRateSourceEditor from "components/editor/sources/ExchangeRateSourceEditor";
import GoogleNewsSourceEditor from "components/editor/sources/GoogleNewsSourceEditor";
import HackerNewsSourceEditor from "components/editor/sources/HackerNewsSourceEditor";
import InboxSourceEditor from "components/editor/sources/InboxSourceEditor";
import ProductHuntSourceEditor from "components/editor/sources/ProductHuntSourceEditor";
import ReadLaterSourceEditor from "components/editor/sources/ReadLaterSourceEditor";
import ReadwiseSourceEditor from "components/editor/sources/ReadwiseSourceEditor";
import RssFeedSourceEditor from "components/editor/sources/RssFeedSourceEditor";
import SeparatorSourceEditor from "components/editor/sources/SeparatorSourceEditor";
import StocksSourceEditor from "components/editor/sources/StocksSourceEditor";
import SubredditSourceEditor from "components/editor/sources/SubredditSourceEditor";
import TextSourceEditor from "components/editor/sources/TextSourceEditor";
import TodaySourceEditor from "components/editor/sources/TodaySourceEditor";
import TwitterLikesSourceEditor from "components/editor/sources/TwitterLikesSourceEditor";
import TwitterListSourceEditor from "components/editor/sources/TwitterListSourceEditor";
import TwitterSearchSourceEditor from "components/editor/sources/TwitterSearchSourceEditor";
import TwitterTimelineSourceEditor from "components/editor/sources/TwitterTimelineSourceEditor";
import TwitterTopLinksSourceEditor from "components/editor/sources/TwitterTopLinksSourceEditor";
import TwitterUserSourceEditor from "components/editor/sources/TwitterUserSourceEditor";
import WeatherSourceEditor from "components/editor/sources/WeatherSourceEditor";
import YouTubeChannelSourceEditor from "components/editor/sources/YouTubeChannelSourceEditor";
import YouTubeSourceEditor from "components/editor/sources/YouTubeSourceEditor";
import PodcastSourceEditor from "components/editor/sources/PodcastSourceEditor";

const sourceTypeToSourceEditor = {
  twitter_user: TwitterUserSourceEditor,
  twitter_search: TwitterSearchSourceEditor,
  twitter_list: TwitterListSourceEditor,
  twitter_timeline: TwitterTimelineSourceEditor,
  twitter_top_links: TwitterTopLinksSourceEditor,
  rss_feed: RssFeedSourceEditor,
  subreddit: SubredditSourceEditor,
  hacker_news: HackerNewsSourceEditor,
  youtube_playlist: YouTubeSourceEditor,
  youtube_channel: YouTubeChannelSourceEditor,
  podcast: PodcastSourceEditor,
  today: TodaySourceEditor,
  google_news: GoogleNewsSourceEditor,
  covid: CovidSourceEditor,
  exchange_rate: ExchangeRateSourceEditor,
  stocks: StocksSourceEditor,
  crypto: CryptoSourceEditor,
  text: TextSourceEditor,
  separator: SeparatorSourceEditor,
  inbox: InboxSourceEditor,
  devto: DevToSourceEditor,
  product_hunt: ProductHuntSourceEditor,
  betalist: BetaListSourceEditor,
  read_later: ReadLaterSourceEditor,
  readwise: ReadwiseSourceEditor,
  calendar: CalendarSourceEditor,
  twitter_likes: TwitterLikesSourceEditor,
  weather: WeatherSourceEditor,
};

export default sourceTypeToSourceEditor;
