import { P2, Spacer } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { ReadLaterInstallInstructions } from "components/ReadLaterInstallInstructions";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import StyledLink from "components/StyledLink";
import urls from "urls";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import PostBodyOption from "../PostBodyOption";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const ReadLaterSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <SourceEditorWrapper>
      <SourceEditorSection noBorders>
        <P2>Send links to Mailbrew to read them later, and see the latest saves in your digest with this source.</P2>
        <Spacer size={4} />
        <StyledLink icon="bookmark" linkStyle={{ width: "100%" }} w="100%" variant="white" to={urls.saved()}>
          See all saved links
        </StyledLink>
      </SourceEditorSection>

      <SourceEditorSection noBorderTop>
        <ReadLaterInstallInstructions header={true} />
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <Spacer size={2} />
        <SourceOptionGrid>
          <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
          <PostBodyOption source={source} sourceIndex={sourceIndex} defaultOption="excerpt" hideFullOption />
        </SourceOptionGrid>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default ReadLaterSourceEditor;
