import { HStack, P1, P2, Switch, VStack } from "@mailbrew/uikit";

const SwitchWithExplanation = (props) => {
  const { title, description, enabled, setEnabled } = props;
  return (
    <>
      <HStack align="spaced">
        <VStack gap={0}>
          <P1 bold>{title}</P1>
          <P2>{description}</P2>
        </VStack>
        <Switch on={enabled} onClick={() => setEnabled(!enabled)} />
      </HStack>
    </>
  );
};

export default SwitchWithExplanation;
