import { useSelector } from "react-redux";
import { Button } from "@mailbrew/uikit";
import { connectTwitterAccount } from "utils/twitterAuth";
import { userTwitterAccountSelector } from "reducers/authReducer";

const ConnectTwitterAccountButton = () => {
  const twitterAccount = useSelector(userTwitterAccountSelector);
  const twitterAccountError = twitterAccount?.error;

  const onClickReconnectTwitterAccount = async () => {
    connectTwitterAccount();
  };

  if (twitterAccountError) {
    return (
      <Button icon="twitter" color="rgba(29,161,242,1.00)" onClick={onClickReconnectTwitterAccount}>
        Re-connect Twitter account
      </Button>
    );
  } else {
    return (
      <Button icon="twitter" color="rgba(29,161,242,1.00)" onClick={onClickReconnectTwitterAccount}>
        Connect Twitter account
      </Button>
    );
  }
};

export default ConnectTwitterAccountButton;
