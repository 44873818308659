import {
  Box,
  Button,
  Center,
  getColorFromCssVariable,
  Input,
  Modal, SimpleDropDown, Stack,
  useBreakpoint,
  useConfig,
  useDarkMode,
  useDetectBrowser
} from "@mailbrew/uikit";
import { useMemo, useState } from "react";
import { HexColorPicker, HslaStringColorPicker } from "react-colorful";
import tinycolor from "tinycolor2";
import PaletteColorPicker, { colorPickerPalette } from "./editor/PaletteColorPicker";

export default function ColorPicker({ width = "100%", color, setColor, mode = "hex", palette }) {
  const config = useConfig();
  const [darkMode] = useDarkMode();

  const startingColor = useMemo(() => {
    if (palette === "backgrounds") {
      return tinycolor(colorPickerPalette?.[palette]?.[0] ?? getColorFromCssVariable(config.colors.c3)).setAlpha(0.06).toHex8String()
    } else {
      return colorPickerPalette?.[palette]?.[0] ?? getColorFromCssVariable(config.colors.c3);
    }
  }, [palette, config.colors.c3]);

  const darkerColor = useMemo(
    () =>
      darkMode
        ? tinycolor(color || startingColor)
            .lighten(12)
            .toString()
        : tinycolor(color || startingColor)
            .darken(12)
            .toString(),
    [color, darkMode, startingColor]
  );

  function handleSetColor(color) {
    if (palette === "backgrounds") {
      const transparentColor = tinycolor(color).setAlpha(0.06).toHex8String();
      setColor(transparentColor);
    } else {
      setColor(color);
    }
  }

  return (
    <Stack noWrap gap={1}>
      <MobileOptimizedDropDown
        content={() => (
          <Stack vertical align="center" gap={4}>
            {palette && <PaletteColorPicker palette={palette} selectedColor={color} onChange={handleSetColor} />}
            {(() => {
              if (mode === "hex") return <HexColorPicker color={color ?? startingColor} onChange={handleSetColor} />;
              if (mode === "hsla") return <HslaStringColorPicker color={color ?? startingColor} onChange={handleSetColor} />;
              return <HslaStringColorPicker color={color ?? startingColor} onChange={handleSetColor} />;
            })()}
          </Stack>
        )}
      >
        <Box
          w="30px"
          h="30px"
          radius="6px"
          border={`1px solid ${darkerColor}`}
          style={{
            background: color || startingColor,
          }}
        />
      </MobileOptimizedDropDown>
      <Input
        wrapperStyle={{ height: "32px" }}
        width={width}
        value={color}
        onChange={(e) => setColor(e.target.value)}
        placeholder="#FF00FF"
        style={{ fontSize: "14px" }}
      />
    </Stack>
  );
}

const MobileOptimizedDropDown = ({ content, children, ...props }) => {
  const hit = useBreakpoint();
  const { hasTouch } = useDetectBrowser();
  const [show, setShow] = useState(false);

  if (hit && hasTouch) {
    return (
      <>
        <div style={{ cursor: "pointer" }} onClick={() => setShow(true)}>
          {children}
        </div>
        <Modal
          show={show}
          setShow={setShow}
          background="transparent"
          shadow="0px 10px 40px -5px rgba(0,0,0,0.2)"
          bottomSheet
          bottomSheetWidth="236px"
          lockScrolling={false}
          hideCloseButton
          zIndex="20"
        >
          <Center>{content()}</Center>
          <Button mt={4} w="100%" variant="secondary" onClick={() => setShow(false)}>
            Done
          </Button>
        </Modal>
      </>
    );
  } else {
    return (
      <SimpleDropDown style={{ padding: "8px" }} content={content}>
        {children}
      </SimpleDropDown>
    );
  }
};
