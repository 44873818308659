import { Box, Button, Center, Expandable, HStack, Icon, P2, SegmentedControl, Spacer, Stack, useConfig } from "@mailbrew/uikit";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import PostBodyOption from "components/editor/PostBodyOption";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import ExternalLink from "components/ExternalLink";
import { InboxAdditionalInstructions, UserInboxAddressCopyBoxWithButton } from "components/NewslettersEmptyState";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import StyledA from "components/StyledA";
import StyledLink from "components/StyledLink";
import StyledUserInboxAddress from "components/StyledUserInboxAddress";
import TitleDivider from "components/TitleDivider";
import { useSWRWithState } from "hooks/swrState";
import useInboxAddress from "hooks/useInboxAddress";
import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import ReactTags from "react-tag-autocomplete";
import { updateSourceField } from "reducers/newslettersReducer";
import supportLinks from "supportLinks";
import useSWR, { mutate } from "swr";
import urls from "urls";
import { groupBy } from "utils/array";
import { notifySourcePreviewForReload } from "utils/cutom-events";
import plausible from "utils/plausible";
import { pluralize } from "utils/pluralize";
import { keySenderOptionsFavCount, keyUserInboxMessagesCount, keyUserInboxTaGs } from "utils/swrKeys";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceEditorCallout from "../SourceEditorCallout";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const InboxSourceEditor = (props) => {

  const config = useConfig();

  const { source, sourceIndex } = props;
  const userInboxEmail = useInboxAddress();
  const { data: countRes } = useSWR(keyUserInboxMessagesCount);

  const [fakeCheckEmailsLoading, setFakeCheckEmailsLoading] = useState(false);

  const noMessages = (() => {
    if (!countRes) return false;
    return countRes.count === 0;
  })();

  useEffect(() => {
    notifySourcePreviewForReload(sourceIndex);
  }, [countRes?.count, sourceIndex]);

  function handleCheckNewEmails() {
    setFakeCheckEmailsLoading(true);
    notifySourcePreviewForReload(sourceIndex);
    setTimeout(() => {
      notifySourcePreviewForReload(sourceIndex);
      setFakeCheckEmailsLoading(false);
    }, 5000);
    mutate(keyUserInboxMessagesCount);
  }

  return (
    <SourceEditorWrapper>
      <SourceEditorSection
        icon="email"
        title="Roll-up your newsletters"
        subtitle={noMessages && "Get your favorite newsletters in Mailbrew."}
        noBorderTop
        noBorderBottom={noMessages}
      >
        <UserInboxAddressCopyBoxWithButton ctaLabel="Copy your address" width="100%" mt={2} stretch email={userInboxEmail} breakAt={9999} />
        {!noMessages && <TitleDivider small title="or forward from" my={2} />}
        {!noMessages && <InboxAdditionalInstructions label={null} style={{ zoom: 0.9 }} breakAt={9999} />}
      </SourceEditorSection>

      {noMessages && <SourceEditorSection noBorderTop noBorderBottom>
        <Center mb={2}>
          <Icon name="arrowDown" color={config.colors.c4}/>
        </Center>
        <P2 color={config.colors.c2} mb={2}>Try subscribing to <ExternalLink href="https://www.julian.com/newsletter">Highlights</ExternalLink> by our friend Julian.</P2>
        <P2 color={config.colors.c2}>Then check for new messages:</P2>
        </SourceEditorSection>}

      <SourceEditorSection noBorderTop={noMessages}>
        <Button
          icon="refresh"
          w="100%"
          variant="secondary"
          loading={fakeCheckEmailsLoading}
          onClick={handleCheckNewEmails}
        >
          Check for new emails
        </Button>
      </SourceEditorSection>

      {!noMessages && (
        <>
          {/* FILTERING */}
          <FilteringSection source={source} sourceIndex={sourceIndex} />
          <NumPostsSourceEditor
            title={`Include max ${source.max_messages} ${pluralize("newsletter", source.max_messages)}`}
            source={source}
            sourceIndex={sourceIndex}
            min={1}
            max={30}
            fieldName="max_messages"
          />
          {/* STYLE OPTIONS */}
          <SourceStyleOptionsWrapper>
            <SourceEditorSection noBorderTop noBorderBottom noPadding noLateralPadding>
              <Spacer size={2} />
            </SourceEditorSection>
            <SourceOptionGrid>
              <PostBodyOption source={source} sourceIndex={sourceIndex} label="Email Preview" hideFullOption />
              <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
            </SourceOptionGrid>
            <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
            <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
          </SourceStyleOptionsWrapper>
        </>
      )}
    </SourceEditorWrapper>
  );
};

const [ALL, FAVS, TAGS] = [1, 2, 3];
const sourceModes = [ALL, FAVS, TAGS];

const FilteringSection = ({ source, sourceIndex }) => {
  const dispatch = useDispatch();
  const { data: userTags = [], state: tagsSwrState } = useSWRWithState(keyUserInboxTaGs, { revalidateOnFocus: true });
  const { data: favCountResponse, state: favCountState } = useSWRWithState(keySenderOptionsFavCount, {
    revalidateOnFocus: true,
  });

  const [selectedFilter, setSelectedFilter] = useState(
    (() => {
      if (source.only_favorites) {
        return FAVS;
      }
      if (!!source.included_tags?.length || !!source.excluded_tags?.length) {
        return TAGS;
      }
      return ALL;
    })()
  );

  useEffect(() => {
    dispatch(updateSourceField(sourceIndex, "only_favorites", selectedFilter === FAVS));
    if (selectedFilter === ALL) {
      dispatch(updateSourceField(sourceIndex, "included_tags", []));
      dispatch(updateSourceField(sourceIndex, "excluded_tags", []));
    }
  }, [dispatch, selectedFilter, sourceIndex]);

  const userTagsById = groupBy("id", userTags);

  const appliedTags = [...(source.included_tags || []), ...(source.excluded_tags || [])];
  const suggestedTags = userTags.filter((tag) => !appliedTags.includes(tag.id));

  const includedTags = source.included_tags?.map((id) => userTagsById[id]).filter((t) => !!t) ?? [];
  const excludedTags = source.excluded_tags?.map((id) => userTagsById[id]).filter((t) => !!t) ?? [];

  const handleAddTag = (tagId, property) => {
    const currentTagIds = source[property] || [];
    const newTagIds = [...currentTagIds, Number(tagId)];
    dispatch(updateSourceField(sourceIndex, property, newTagIds));
  };

  const handleRemoveTag = (tagId, property) => {
    const newTags = source[property].filter((tag) => tag !== tagId);
    dispatch(updateSourceField(sourceIndex, property, newTags));
  };

  return (
    <SourceEditorSection title="Filter newsletters" icon="filterBold">
      {(() => {
        if (tagsSwrState === "loading" || favCountState === "loading") {
          return null;
        }

        if (tagsSwrState === "error" || favCountState === "error") {
          return (
            <SourceEditorCallout
              icon="warning"
              title="There was an error loading your tags."
              body="Please reload the page to retry."
              mt={0}
              mb={0}
              color={"hsl(35, 100%, 30%)"}
            />
          );
        }

        if (tagsSwrState === "empty" && favCountResponse.count === 0) {
          return (
            <>
              <StyledA
                aStyle={{ width: "100%" }}
                href={supportLinks.manageNewsletters}
                icon="bookmark"
                w="100%"
                variant="white"
                mt={1}
                targetBlank
              >
                See how to tag & favorite
              </StyledA>
            </>
          );
        }

        return (
          <>
            <SegmentedControl
              options={sourceModes}
              optionsNames={["All", "Favorites", "Tags"]}
              optionsIcons={["", "starBold", "search"]}
              active={selectedFilter}
              onOptionChange={setSelectedFilter}
            />
            <Expandable expanded={selectedFilter === TAGS} mt={4}>
              <Stack vertical w="100%" align="stretch" gap={4}>
                <TagsField icon="checkmark" title="Include Messages Tagged">
                  <TagEditor
                    placeholder="Include tags..."
                    className="react-tags include"
                    tags={includedTags}
                    suggestedTags={suggestedTags}
                    onAddTag={(tagID) => {
                      handleAddTag(tagID, "included_tags");
                    }}
                    onRemoveTag={(tagID) => {
                      handleRemoveTag(tagID, "included_tags");
                    }}
                  />
                </TagsField>
                <TagsField icon="delete" title="Exclude Messages Tagged">
                  <TagEditor
                    placeholder="Exclude tags..."
                    className="react-tags exclude"
                    tags={excludedTags}
                    suggestedTags={suggestedTags}
                    onAddTag={(tagID) => {
                      handleAddTag(tagID, "excluded_tags");
                    }}
                    onRemoveTag={(tagID) => {
                      handleRemoveTag(tagID, "excluded_tags");
                    }}
                  />
                </TagsField>
                <StyledLink icon="bookmark" w="100%" variant="white" to={urls.manageInbox()} mt={1}>
                  Manage tags
                </StyledLink>
              </Stack>
            </Expandable>
          </>
        );
      })()}
    </SourceEditorSection>
  );
};

const TagsField = ({ title, icon, children }) => {
  return (
    <Box>
      <HStack mb={2}>
        <Icon name={icon} />
        <P2>{title}</P2>
      </HStack>
      {children}
    </Box>
  );
};

const TagEditor = ({ tags, onAddTag, onRemoveTag, suggestedTags, placeholder, className }) => {
  const config = useConfig();

  const tagToReactTag = (tag) => ({ ...tag, name: tag.title });

  const tagsReactTags = tags.map(tagToReactTag);
  const suggestedReactTags = suggestedTags.map(tagToReactTag);

  async function handleAddTag(tag) {
    onAddTag(tag.id);
  }

  async function handleRemoveTag(tagIndex) {
    const tag = tags[tagIndex];
    if (!tag) return;
    onRemoveTag(tag.id);
  }

  return (
    <div
      style={{
        width: "100%",
        minHeight: config.Input.height,
        padding: "6px 9px 0px",
        color: config.Input.color,
        border: config.Input.borderWidth + " solid " + config.colors.uiBorderColor,
        background: config.Input.background,
        borderRadius: config.Input.radius,
        boxSizing: "border-box",
      }}
      className={className}
    >
      <ReactTags
        placeholderText={placeholder}
        tags={tagsReactTags}
        suggestions={suggestedReactTags}
        onAddition={handleAddTag}
        onDelete={handleRemoveTag}
      />
    </div>
  );
};

export const CopyWrapper = ({ copyText, children, track, ...rest }) => {
  const config = useConfig();

  const [copied, setCopied] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    // cleanup
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const handleCopy = () => {
    plausible.track(track);
    setCopied(true);
    timeout.current = setTimeout(() => setCopied(false), 2000);
  };

  return (
    <CopyToClipboard text={copyText}>
      <Box onClick={handleCopy} style={{ cursor: "pointer" }} {...rest}>
        <HStack align="center">
          {copied && <Icon name="checkmark" size="1rem" color={config.colors.c3} />}
          <P2 fontSize="13px" align="center" color={config.colors.c3} mb={1}>
            {copied ? "Copied" : "Click to copy"}
          </P2>
        </HStack>
        {children}
      </Box>
    </CopyToClipboard>
  );
};

export const UserInboxAddressCopyBox = ({ email }) => {
  return (
    <CopyWrapper copyText={email} mt={1}>
      <HStack align="center">
        <StyledUserInboxAddress email={email} stretch />
      </HStack>
    </CopyWrapper>
  );
};

export default InboxSourceEditor;
