import { Grid, Icon, P2, Select, Spacer, useConfig } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceEditorCallout from "components/editor/SourceEditorCallout";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ExternalLink from "components/ExternalLink";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const MAX_TARGETS = 9;

const ExchangeRateSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const { data: currencies, error: currenciesApiError } = useSWR("/currencies/");

  const handleAddTarget = () => {
    const code = source.base === "EUR" ? "USD" : "EUR";
    dispatch(updateSourceField(sourceIndex, "targets", [...source.targets, code]));
  };

  const handleRemoveTarget = (index) => {
    const newTargets = [...source.targets];
    newTargets.splice(index, 1);
    dispatch(updateSourceField(sourceIndex, "targets", newTargets));
  };

  const handleUpdateTarget = (index, currencyCode) => {
    const newTargets = [...source.targets];
    newTargets[index] = currencyCode;
    dispatch(updateSourceField(sourceIndex, "targets", newTargets));
  };

  return (
    <SourceEditorWrapper>
      <SourceEditorSection title="Base Currency" icon="currency" noBorderTop>
        {currencies ? (
          <Select
            selectedOption={source.base}
            onSelect={(c) => {
              dispatch(updateSourceField(sourceIndex, "base", c));
            }}
            options={currencies.map((c) => c.code)}
            optionsNames={currencies.map(formatCurrency)}
            error={currenciesApiError}
          />
        ) : (
          <Select placeholder="Loading Currencies..." options={[]} error={currenciesApiError} />
        )}
        <SourceEditorCallout
          icon="growth"
          title="About the diffs"
          body="Diffs based on previous working day exchange rates."
        />
        <Spacer size="xs" />
        <P2>
          Data from{" "}
          <ExternalLink href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html">
            European Central Bank
          </ExternalLink>
          , via this <ExternalLink href="https://exchangeratesapi.io/">public API</ExternalLink>.
        </P2>
      </SourceEditorSection>

      <SourceEditorSection title="Target Currencies" icon="currencyExchange">
        {currencies &&
          source.targets.map((target, index) => (
            <Fragment key={`${target}-${index}`}>
              <TargetCurrencyRow
                currencyCode={target}
                currencies={currencies}
                canAddTarget={index === source.targets.length - 1 && source.targets.length < MAX_TARGETS}
                canRemoveTarget={source.targets.length > 1}
                onAddTarget={handleAddTarget}
                onRemoveTarget={() => handleRemoveTarget(index)}
                onUpdateTarget={(currencyCode) => handleUpdateTarget(index, currencyCode)}
              />
              <Spacer size="xs" />
            </Fragment>
          ))}
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

const TargetCurrencyRow = ({
  currencyCode,
  canRemoveTarget,
  canAddTarget,
  currencies,
  onAddTarget,
  onRemoveTarget,
  onUpdateTarget,
}) => {
  return (
    <Grid columns="1fr 1em 1em" vAlign="center" width="100%">
      <Select
        selectedOption={currencyCode}
        onSelect={(c) => {
          onUpdateTarget(c);
        }}
        options={currencies.map((c) => c.code)}
        optionsNames={currencies.map(formatCurrency)}
      />
      <RemoveButton onClick={onRemoveTarget} disabled={!canRemoveTarget} />
      <AddButton onClick={onAddTarget} disabled={!canAddTarget} />
    </Grid>
  );
};

const RemoveButton = ({ onClick, disabled }) => {
  const config = useConfig();
  return (
    <Icon
      color={disabled ? config.colors.c5 : config.colors.accent1}
      name="minusBold"
      size="22px"
      onClick={disabled ? undefined : onClick}
    />
  );
};

const AddButton = ({ onClick, disabled }) => {
  const config = useConfig();
  return (
    <Icon
      color={disabled ? config.colors.c5 : config.colors.accent1}
      name="plusBold"
      size="22px"
      onClick={disabled ? undefined : onClick}
    />
  );
};

const formatCurrency = (c) => `${c.code}`;

export default ExchangeRateSourceEditor;
