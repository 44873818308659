import { Box, HStack, Icon, P2, Select, useConfig } from "@mailbrew/uikit";
import { Disable } from "react-disable";

const DropDownRow = (props) => {
  const { label, icon, selected, onChange, disabled, options, optionsNames, pt = 1, pb = 1, ...rest } = props;

  const config = useConfig();

  return (
    <Box pt={pt} pb={pb} {...rest}>
      <Disable disabled={disabled}>
        <HStack align="spaced">
          <HStack align="left">
            <Icon name={icon} color={config.colors.c2} />
            <P2 color={config.colors.c2}>{label}</P2>
          </HStack>
          <Select
            selectedOption={selected}
            options={options}
            optionsNames={optionsNames}
            onSelect={onChange}
            width="170px"
          />
        </HStack>
      </Disable>
    </Box>
  );
};

export default DropDownRow;
