import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import PopularOrLatestSelector from "components/editor/PopularOrLatestSelector";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxTweetsPerUserSection from "components/MaxTweetsPerUserSection";
import MaxTweetsSection from "components/MaxTweetsSection";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";
import { notifySourcePreviewForReload } from "utils/cutom-events";

const minTweets = 1;
const maxPosts = 200;

const TwitterTimelineSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const handleConnectedTwitterAccountChange = () => {
    notifySourcePreviewForReload(sourceIndex);
  };

  return (
    <SourceEditorWrapper>
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        <Disable disabled={!twitterAccountConnected}>
          <SourceEditorSection title="Tweets to include" icon="twitter">
            {/* MODE */}
            <PopularOrLatestSelector source={source} sourceIndex={sourceIndex} />
            {/* RETWEETS */}
            <SettingSwitchRow
              mt={3}
              icon="retweet"
              copy="Show Retweets"
              state={source.show_retweets}
              onChange={() => {
                dispatch(updateSourceField(sourceIndex, "show_retweets", !source.show_retweets));
              }}
            />
          </SourceEditorSection>

          {/* MAX NUMBER OF TWEETS */}
          <MaxTweetsSection source={source} sourceIndex={sourceIndex} min={minTweets} max={maxPosts} />

          {/* MAX TWEETS PER USER */}
          <MaxTweetsPerUserSection source={source} sourceIndex={sourceIndex} />

          <SourceStyleOptionsWrapper>
            <SourceOptionGrid>
              <ImagesStyleSetting
                style={source.images_style}
                onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
                pb={0}
                pt={0}
              />
              <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
            </SourceOptionGrid>
            <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
            <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
          </SourceStyleOptionsWrapper>
        </Disable>
      </Disable>

      {twitterAccountConnected && (
        <SourceEditorSection noPadding noBorderBottom>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

export default TwitterTimelineSourceEditor;
