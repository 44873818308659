import { SliderWithInput } from "@mailbrew/uikit";
import SourceEditorSection from "components/editor/SourceEditorSection";
import InfoButton from "components/InfoButton";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";

export default function MaxLinksSection({ source, sourceIndex, min, max, children }) {
  const dispatch = useDispatch();

  return (
    <SourceEditorSection
      icon="list"
      title="Max Number of Links"
      headerExtras={
        <InfoButton
          title="Which links are included?"
          description={`We'll include up to ${source.max_links} links, shared since the previous issue of this brew.`}
          interactive={false}
        />
      }
    >
      <SliderWithInput
        min={min}
        max={max}
        value={source.max_links}
        onChange={(value) => dispatch(updateSourceField(sourceIndex, "max_links", parseInt(value)))}
      />
      {children}
    </SourceEditorSection>
  );
}
