import { useEditorSourceId } from "pages/edit/[brewId]/[[...args]]";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { currentNewsletterSelector } from "reducers/newslettersReducer";
import sourceTypeToSourceEditor from "utils/sourcesEditors";

/**
 * Selects the correct editor based on the source type of the select source (read from the url).
 */
const SubeditorSelector = ({ sourceId: providedSourceId }) => {
  const newsletter = useSelector(currentNewsletterSelector);
  const _sourceId = useEditorSourceId();
  const sourceId = useMemo(() => providedSourceId ?? _sourceId, [_sourceId, providedSourceId]);

  const intSourceIndex = useMemo(() => {
    if (/^\d+$/.test(sourceId)) {
      return parseInt(sourceId);
    } else {
      const sourceIndex = newsletter.sources?.findIndex((s) => s.id === sourceId);
      return sourceIndex;
    }
  }, [newsletter.sources, sourceId]);

  if (!newsletter) return null;

  const source = newsletter?.sources?.[intSourceIndex];

  if (!source?.type) return null;
  const Component = sourceTypeToSourceEditor[source.type];

  // we pass the source index as a key to these sources so that when the index changes
  // with this component mounted (e.g. when "add another" is clicked) we trigger a re-render
  return <Component key={intSourceIndex} source={source} sourceIndex={intSourceIndex} />;
};

export default SubeditorSelector;
