import { Callout, getColorFromCssVariable, useConfig } from "@mailbrew/uikit";
import tinycolor from "tinycolor2";

const SourceEditorCallout = ({ color, ...otherProps }) => {
  const config = useConfig();

  const borderColor = color ? tinycolor(getColorFromCssVariable(color)).darken(10).setAlpha(0.15).toString() : null;

  return (
    <Callout
      alphas={{
        background: 0,
        body: 0.8,
      }}
      style={{
        padding: "12px 14px",
        border: `1px solid ${borderColor ?? config.colors.uiBorderColor}`,
      }}
      borders={null}
      noShadow={false}
      mt={4}
      mb={4}
      {...otherProps}
      color={color || config.colors.c2}
    />
  );
};

export default SourceEditorCallout;
