import { Button, Input, P2, Spacer } from "@mailbrew/uikit";
import ColorPicker from "components/ColorPicker";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import ExternalLink from "components/ExternalLink";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, setUser } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce/lib";
import { notifySourcePreviewForReload } from "utils/cutom-events";
import { pluralize } from "utils/pluralize";
import * as Yup from "yup";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const ReadwiseSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);

  const [debounceUpdateReadwiseToken] = useDebouncedCallback((readwise_token) => {
    api.post("/update_user/", { readwise_token }).then((res) => {
      dispatch(setUser(res.data));
      notifySourcePreviewForReload(sourceIndex);
    });
  }, 500);

  const [readwiseToken, setReadwiseToken, readwiseTokenError] = useInputValidator(
    user.readwise_token ?? "",
    Yup.string().max(256, "Type a shorter token."),
    (value) => {
      debounceUpdateReadwiseToken(value);
    }
  );

  return (
    <SourceEditorWrapper>
      <SourceEditorSection noBorders>
        <P2>Add your Readwise daily review.</P2>
        <Spacer size={4} />
        <Input
          placeholder="Readwise Token"
          value={readwiseToken}
          onChange={(e) => setReadwiseToken(e.target.value)}
          type="password"
          error={readwiseTokenError}
        />
        <Button
          variant="white"
          w="100%"
          icon="key"
          iconSize="14px"
          mt={2}
          onClick={() => window.open("https://readwise.io/access_token", "_blank")}
        >
          Get Your Token
        </Button>
      </SourceEditorSection>

      <NumPostsSourceEditor
        title={`Include ${source.num_posts} ${pluralize("quote", source.num_posts)}`}
        source={source}
        sourceIndex={sourceIndex}
        fieldName="num_posts"
        min={1}
        max={3}
      />

      <SourceEditorSection title="Color" icon="palette">
        <ColorPicker
          mode="hsla"
          color={source.quote_color}
          setColor={(newColor) => dispatch(updateSourceField(sourceIndex, "quote_color", newColor))}
          palette="backgrounds"
        />
      </SourceEditorSection>

      <SourceEditorSection title="About Readwise" icon="info">
        <P2>
          You can learn more about Readwise and create an account over at{" "}
          <ExternalLink href="https://readwise.io/?ref=mailbrew">readwise.io</ExternalLink>.
        </P2>
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default ReadwiseSourceEditor;
