import { useEffect, useRef, useState } from "react";
import randomItem from "../utils/randomItem";

export default function useRandomItem(arr, { defaultItem = "", refreshInterval = 0 } = {}) {
  const [item, setItem] = useState(defaultItem);
  const intervalRef = useRef();

  useEffect(() => {
    setItem(randomItem(arr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!refreshInterval && !intervalRef.current) {
      return;
    }

    // clear previously set interval
    if (!refreshInterval && intervalRef.current) {
      const interval = intervalRef.current;
      intervalRef.current = null;
      return () => clearInterval(interval);
    }

    intervalRef.current = setInterval(() => {
      setItem(randomItem(arr));
    }, refreshInterval);

    return () => clearInterval(intervalRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval]);

  return [item];
}
