import { Icon, Select, SmallText, Stack, useConfig } from "@mailbrew/uikit";

export default function CompactSelectSetting({
  icon,
  iconSize = 14,
  label,
  selectedOption,
  setSelectedOption,
  onChange,
  options,
  optionsIds,
  optionsNames,
  disabled,
}) {
  const config = useConfig();

  const typeNumber = !isNaN(optionsIds?.[0] ?? options?.[0]?.id);

  return (
    <Stack align="stretch" vertical gap={1}>
      <Stack gap={1}>
        <Icon name={icon} size={iconSize} color={config.colors.c3} />
        <SmallText>{label}</SmallText>
      </Stack>
      <Select
        options={optionsIds ?? options?.map((o) => o.id)}
        optionsNames={optionsNames ?? options?.map((o) => o.name)}
        onSelect={(option) => {
          const parsedOption = typeNumber ? parseInt(option) : option;
          setSelectedOption && setSelectedOption(parsedOption);
          onChange && onChange(parsedOption);
        }}
        selectedOption={selectedOption}
        disabled={disabled}
      />
    </Stack>
  );
}
