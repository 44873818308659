import { AutoGrid, Expandable, Icon, SmallText, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import useCheckCanUseAdvancedLayout from "hooks/useCheckCanUseAdvancedLayout";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import ColorPicker from "./ColorPicker";
import CompactSelectSetting from "./CompactSelectSetting";
import { BREW_BREAKPOINT } from "./editor/BrewEditor";

export default function SourceStyleEditor({ source, sourceIndex }) {
  const config = useConfig();
  const isMobileBrew = useBreakpoint(BREW_BREAKPOINT);
  const dispatch = useDispatch();
  const checkCanUse = useCheckCanUseAdvancedLayout();

  if (!source) return null;

  const style = source.style;
  const setStyle = (value) => checkCanUse(() => dispatch(updateSourceField(sourceIndex, "style", value)));

  const styleColor = source.style_color;
  const setStyleColor = (value) => checkCanUse(() => dispatch(updateSourceField(sourceIndex, "style_color", value)));

  return (
    <>
      <AutoGrid fill={false} overflow="visible" gap={1} style={{ flex: 1 }} mt={2}>
        {!isMobileBrew && <SourceSizeSetting source={source} sourceIndex={sourceIndex} />}
        <CompactSelectSetting
          label="Style"
          icon="layout"
          options={styleOptions}
          selectedOption={style}
          setSelectedOption={setStyle}
        />
      </AutoGrid>
      <Expandable expanded={style === "colorful"}>
        <Stack vertical align="stretch" gap={1} mt={3}>
          <Stack noWrap gap={1}>
            <Icon name="bucket" size={14} color={config.colors.c3} />
            <SmallText>Background</SmallText>
          </Stack>
          <ColorPicker color={styleColor} setColor={setStyleColor} palette="backgrounds" />
        </Stack>
      </Expandable>
    </>
  );
}

export const SourceSizeSetting = ({ source, sourceIndex }) => {
  const dispatch = useDispatch();
  const checkCanUse = useCheckCanUseAdvancedLayout();

  if (!source) return null;

  const width = source.width;
  const setWidth = (value) => checkCanUse(() => dispatch(updateSourceField(sourceIndex, "width", value)));

  return (
    <CompactSelectSetting
      label="Size"
      icon="selectHorizontal"
      options={widthOptions}
      selectedOption={width}
      setSelectedOption={setWidth}
    />
  );
};

const widthOptions = [
  {
    id: "compact",
    name: "Compact",
  },
  {
    id: "wide",
    name: "Wide",
  },
  {
    id: "ultra_wide",
    name: "Ultra wide",
  },
];

const styleOptions = [
  {
    id: "simple",
    name: "Simple",
  },
  {
    id: "card",
    name: "Card",
  },
  {
    id: "colorful",
    name: "Colorful",
  },
];
