import { SliderWithInput } from "@mailbrew/uikit";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import SourceEditorSection from "./SourceEditorSection";

export default function NumPostsSourceEditor({
  title,
  source,
  sourceIndex,
  fieldName = "num_posts",
  min,
  max,
  noBorderTop,
  noBorderBottom,
  noWrappingSection,
}) {
  const dispatch = useDispatch();

  const value = source[fieldName];

  return (
    <Wrapper
      noWrappingSection={noWrappingSection}
      noBorderTop={noBorderTop}
      noBorderBottom={noBorderBottom}
      title={title}
    >
      <SliderWithInput
        min={min}
        max={max}
        value={value}
        onChange={(value) => dispatch(updateSourceField(sourceIndex, fieldName, parseInt(value)))}
      />
    </Wrapper>
  );
}

const Wrapper = ({ children, noWrappingSection, noBorderTop, noBorderBottom, title }) =>
  noWrappingSection ? (
    <>{children}</>
  ) : (
    <SourceEditorSection
      noBorderTop={noBorderTop}
      noBorderBottom={noBorderBottom}
      title={title ?? "Included Posts"}
      icon="list"
      noPadding
    >
      {children}
    </SourceEditorSection>
  );
