import { Box, Icon, P1, Stack, useConfig } from "@mailbrew/uikit";
import { sourcesData } from "data/sourcesData";
import { motion } from "framer-motion";
import { useState } from "react";

const AddSourceBoxSubtle = ({
  title: providedTitle,
  sourceType,
  onClick,
  info: providedInfo,
  small,
  checked,
  disabled,
}) => {
  const config = useConfig();
  const [isHovering, setIsHovering] = useState(false);

  const source = sourcesData[sourceType] ?? {};
  let name = providedTitle ?? source.name;
  let { shortName, color: sourceColor, glyph: SourceIcon } = source;

  const color = isHovering ? sourceColor : config.colors.c3;

  return (
    <Box
      onClick={() => !disabled && onClick && onClick()}
      style={{
        position: "relative",
        cursor: onClick ? "pointer" : "auto",
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {checked && <IconChecked />}
      <Stack vertical align="center" gap={0.5}>
        <SourceIcon style={{ width: "28px", height: "28px" }} color={color} />
        <P1 fontSize={small ? "13px" : "14.5px"} align="center" noWrap color={color} weight="500">
          {providedTitle ? name ?? shortName : shortName ?? name}
        </P1>
      </Stack>
    </Box>
  );
};

const IconChecked = () => {
  const config = useConfig();
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      style={{ position: "absolute", top: "5px", right: "5px" }}
    >
      <Icon size={20} color={config.colors.success} name="checkmarkCircle" />
    </motion.div>
  );
};

export default AddSourceBoxSubtle;
