import { SegmentedControl } from "@mailbrew/uikit";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { searchWeatherLocation } from "utils/sourceSuggestionFetchers";
import SearchField from "../SearchField";
import SettingSwitchRow from "../SettingSwitchRow";
import SourceEditorSection from "../SourceEditorSection";
import SourceEditorWrapper from "../SourceEditorWrapper";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

export default function WeatherSourceEditor({ source, sourceIndex }) {
  const dispatch = useDispatch();

  return (
    <SourceEditorWrapper>
      <SourceEditorSection icon="locationBold" title="Location" noBorderTop>
        <SearchField
          initialValue={source.location_name}
          placeholder={"Search city"}
          autoFocus={!source.location_key}
          fetchSuggestions={searchWeatherLocation}
          onSuggestionClick={(suggestion, index, payload) => {
            if (!payload) return;
            dispatch(updateSourceField(sourceIndex, "location_name", payload.title));
            dispatch(updateSourceField(sourceIndex, "location_key", payload.location_key));
          }}
        />
      </SourceEditorSection>

      <SourceEditorSection title="Forecast Interval" icon="calendar">
        <SegmentedControl
          options={["daily", "five_days"]}
          optionsNames={["Daily (12h)", "5 Days"]}
          active={source.forecast_interval ?? "daily"}
          onOptionChange={(payload) => {
            dispatch(updateSourceField(sourceIndex, "forecast_interval", payload));
          }}
        />
      </SourceEditorSection>

      <SourceEditorSection title="Unit System" icon="stats">
        <SegmentedControl
          options={["m", "i"]}
          optionsNames={["Metric", "Imperial"]}
          active={source.is_metric ?? true ? "m" : "i"}
          onOptionChange={(payload) => {
            dispatch(updateSourceField(sourceIndex, "is_metric", payload === "m" ? true : false));
          }}
        />
      </SourceEditorSection>

      {source.forecast_interval === "daily" && (
        <>
          <SourceEditorSection title="Extras" icon="rows">
            <SettingSwitchRow
              state={source.hide_summary ?? false}
              onChange={(on) => dispatch(updateSourceField(sourceIndex, "hide_summary", on))}
              copy="Hide summary in daily view"
              icon="text"
            />
            <SettingSwitchRow
              state={source.show_sunrise_sunset ?? false}
              onChange={(on) => dispatch(updateSourceField(sourceIndex, "show_sunrise_sunset", on))}
              copy="Show sunrise and sunset times"
              icon="sun"
            />
          </SourceEditorSection>
        </>
      )}
      <SourceStyleOptionsWrapper>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
}
