import CompactSelectSetting from "components/CompactSelectSetting";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";

// onChange, selected
const PostBodyOption = ({
  source,
  sourceIndex,

  label,
  disabled,

  defaultOption = "hidden",
  hideFullOption,

  ...rest
}) => {
  const dispatch = useDispatch();
  let options = ["hidden", "excerpt", !hideFullOption && "full"];
  let optionsNames = ["Hidden", "Excerpt", !hideFullOption && "Full"];

  options = options.filter((o) => o);
  optionsNames = optionsNames.filter((o) => o);

  function handleChange(option) {
    dispatch(updateSourceField(sourceIndex, "post_body", option));
  }

  return (
    <CompactSelectSetting
      label={label ?? "Post Body"}
      icon="text"
      selectedOption={source.post_body || defaultOption}
      onChange={handleChange}
      disabled={disabled}
      optionsIds={options}
      optionsNames={optionsNames}
      {...rest}
    />
  );
};

export default PostBodyOption;
