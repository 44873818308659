import { Card, Modal, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { AnimatePresence, motion } from "framer-motion";

export const DRAGGABLE_MODAL_BREAKPOINT = 800;

export default function SourceEditorModal({
  show,
  onClose,
  maxMobileHeight = "50vh",
  fixedHeight = false,
  maxHeight = "65vh",
  children,
  ...otherProps
}) {
  const config = useConfig();
  const hit = useBreakpoint(DRAGGABLE_MODAL_BREAKPOINT);

  if (hit) {
    return (
      <Modal
        style={{ padding: 0 }}
        show={show}
        onClose={onClose}
        background="transparent"
        shadow="0px 10px 40px -5px rgba(0,0,0,0.2)"
        bottomSheet
        bottomSheetWidth="440px"
        lockScrolling={false}
        hideCloseButton
      >
        <div style={{ overflow: "hidden", marginBottom: "calc(0px - env(safe-area-inset-bottom))" }}>
          <div
            style={{
              overflow: "scroll",
              paddingBottom: "env(safe-area-inset-bottom)",
              maxHeight: maxMobileHeight,
            }}
          >
            {children}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 25, opacity: 0 }}
            transition={{ type: "tween", duration: 0.15 }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 5,
              pointerEvents: "none",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              padding: config.layout.padding,
            }}
          >
            <div
              style={{
                height: "auto",
                width: "360px",
                pointerEvents: "none",
              }}
            >
              <Card
                inline
                width="100%"
                shadow="0px 10px 40px -5px rgba(0,0,0,0.15)"
                p={0}
                style={{ pointerEvents: "all" }}
                {...otherProps}
              >
                <div
                  style={{
                    overflow: "scroll",
                    maxHeight: maxHeight,
                    minHeight: 220,
                    height: fixedHeight ? maxHeight : "auto",
                  }}
                >
                  {children}
                </div>
              </Card>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export const SourceEditorModalHeader = ({ children, ...otherProps }) => {
  const config = useConfig();
  return (
    <Stack
      noWrap
      align="right"
      pt={config.layout.padding}
      px={config.layout.padding}
      pb={config.layout.padding}
      style={{
        position: "sticky",
        top: 0,
        background: config.colors.bg0,
        borderTopLeftRadius: config.Card.bigRadius,
        borderTopRightRadius: config.Card.bigRadius,
        zIndex: 3,
      }}
    >
      {children}
    </Stack>
  );
};
