import { SliderWithInput, Switch } from "@mailbrew/uikit";
import SourceEditorSection from "components/editor/SourceEditorSection";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import numberValidator from "utils/numberValidator";
import { pluralize } from "utils/pluralize";

export default function MaxTweetsPerUserSection({ source, sourceIndex }) {
  const dispatch = useDispatch();

  const [maxTweetsPerUser, setMaxTweetsPerUser, maxTweetsPerUserError] = useInputValidator(
    source.max_tweets_per_user,
    numberValidator(1, 10),
    (value) => dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", parseInt(value)))
  );

  return (
    <SourceEditorSection
      title={`Max ${source.max_tweets_per_user ?? ""} ${pluralize("tweet", source.max_tweets_per_user)} per user`}
      icon="userBold"
      headerExtras={
        <Switch
          on={source.max_tweets_per_user > 0}
          onClick={() => {
            dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", source.max_tweets_per_user > 0 ? null : 3));
          }}
        />
      }
    >
      {source.max_tweets_per_user > 0 && (
        <SliderWithInput
          min={1}
          max={10}
          value={maxTweetsPerUser}
          onChange={(value) => setMaxTweetsPerUser(value)}
          error={maxTweetsPerUserError}
        />
      )}
    </SourceEditorSection>
  );
}
