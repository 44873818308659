import { P2, Select } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceEditorCallout from "components/editor/SourceEditorCallout";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ExternalLink from "components/ExternalLink";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const CovidSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const { data: countries, error: countriesApiError } = useSWR("/covid_countries/");

  return (
    <SourceEditorWrapper>
      <SourceEditorSection title="Country" icon="worldAltBold" noBorderTop>
        {countries ? (
          <Select
            selectedOption={source.country}
            onSelect={(c) => {
              dispatch(updateSourceField(sourceIndex, "country", c));
              dispatch(updateSourceField(sourceIndex, "country_name", countries[c]));
            }}
            options={Object.keys(countries)}
            optionsNames={Object.values(countries)}
            error={countriesApiError}
          />
        ) : (
          <Select placeholder="Loading Countries..." options={[]} error={countriesApiError} />
        )}
        <SourceEditorCallout
          icon="growth"
          title="About the diffs"
          body="The diffs are based on the previous issue of your brew, or the latest data available as of yesterday."
        />
        <P2>
          Data from <ExternalLink href="https://coronavirus.jhu.edu/map.html">Johns Hopkins University</ExternalLink>,
          via this <ExternalLink href="https://github.com/mathdroid/covid-19-api">public API</ExternalLink>.
        </P2>
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default CovidSourceEditor;
