import DropDownRow from "components/DropDownRow";

const TweetsFilterSetting = (props) => {
  const { selected, onChange, disabled, ...rest } = props;

  const options = [null, "only_images", "no_images"];
  const optionsNames = ["All Tweets", "Only Images", "No Images"];

  return (
    <DropDownRow
      label="Filter Tweets"
      icon="search"
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      options={options}
      optionsNames={optionsNames}
      {...rest}
    />
  );
};

export default TweetsFilterSetting;
