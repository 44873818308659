import { Button, Center, H2, Modal, P1, P2, useBreakpoint } from "@mailbrew/uikit";
import api from "dependencies/api";
import { useState } from "react";
import { useSelector } from "react-redux";
import { currentNewsletterSelector } from "reducers/newslettersReducer";
import notifApiError from "utils/notifApiError";
import makeSourceTemplate from "utils/sourcesMakeTemplate";
import TwitterAccountsSelector from "./TwitterAccountsSelector";

export default function TwitterDigestModal({ show, setShow, onSourceCreated }) {
  const brew = useSelector(currentNewsletterSelector);
  const isMobile = useBreakpoint();
  const [ctaLoading, setCtaLoading] = useState(false);

  const listTitle = (() => {
    if (brew && brew.title.length > 0) {
      return `Mailbrew - ${brew.title}`;
    } else {
      return "Mailbrew Digest";
    }
  })();

  const handleCreateTwitterDigest = async (sources) => {
    const screen_names = sources.map((s) => s.screen_name);

    try {
      setCtaLoading(true);
      const res = await api.post("onboarding/create_brew_twitter_list/", { title: listTitle, screen_names });
      const listId = res.data.list_id;
      const source = makeSourceTemplate("twitter_list");
      source.list_id = listId;
      source.title = "Twitter Digest";
      if (screen_names.length > 7) source.max_tweets_per_user = 1;

      onSourceCreated(source);
      setShow(false);
    } catch (err) {
      notifApiError(err, "Error creating the Twitter list");
    } finally {
      setCtaLoading(false);
    }
  };

  return (
    <Modal
      lockScrolling={false}
      show={show}
      setShow={setShow}
      width="70em"
      fullScreenAt={960}
      style={{
        height: "50em",
        minHeight: "28em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {isMobile ? <H2>Select your favorites</H2> : <H2>Select your favorite accounts</H2>}

      <P1 mb={4}>Never miss their best tweets in your digest.</P1>
      <TwitterAccountsSelector isReadWriteTokenRequired scrollable>
        {(sources) => {
          return <CreateDigestButton sources={sources} onClick={handleCreateTwitterDigest} loading={ctaLoading} />;
        }}
      </TwitterAccountsSelector>
    </Modal>
  );
}

const CreateDigestButton = ({ sources, loading, onClick }) => {
  const disabled = sources.length < 3 || sources.length > 100;

  let notice =
    sources.length < 3 ? (
      <P2 mt={1} centerAt={960}>
        Select at least 3 accounts to continue.
      </P2>
    ) : sources.length > 100 ? (
      <P2 mt={1} centerAt={960}>
        You can select at most 100 accounts.
      </P2>
    ) : (
      <P2 mt={1} centerAt={960}>
        We'll create a private Twitter list that you can update on Twitter.
      </P2>
    );

  return (
    <Center mt={4}>
      <Button disabled={disabled} onClick={() => onClick(sources)} softLoading={loading}>
        Continue
      </Button>
      {notice}
    </Center>
  );
};
