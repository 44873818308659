import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import CompactSelectSetting from "./CompactSelectSetting";

export default function SharesStyleSetting({ source, sourceIndex }) {
  const dispatch = useDispatch();
  return (
    <CompactSelectSetting
      label="Comments"
      icon="bubble"
      selectedOption={source.shares_style}
      optionsIds={["hidden", "avatars", "full"]}
      optionsNames={["Hide", "Just avatars", "Show comments"]}
      onChange={(style) => dispatch(updateSourceField(sourceIndex, "shares_style", style))}
    />
  );
}
