import CompactSelectSetting from "components/CompactSelectSetting";

const ImagesShow = (props) => {
  const { state, onChange, disabled } = props;

  return (
    <CompactSelectSetting
      label="Images"
      icon="pictureBold"
      options={[
        {
          id: "show",
          name: "Show",
        },
        {
          id: "hide",
          name: "Hide",
        },
      ]}
      selectedOption={state ? "show" : "hide"}
      setSelectedOption={(option) => onChange(option === "show")}
      disabled={disabled}
    />
  );
};

export default ImagesShow;
