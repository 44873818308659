import useSWR, { ConfigInterface, keyInterface, responseInterface, SWRConfig } from "swr";
import { fetcherFn } from "swr/dist/types";

type SWRState = "loading" | "loaded" | "empty" | "error";

export function calcSwrState<Data = any, Error = any>(swrResult: responseInterface<Data, Error>): SWRState {
  const { data, error } = swrResult;

  if (error) return "error";

  if (!data) return "loading";

  // @ts-ignore
  if (data?.length === 0 || data?.results?.length === 0 || data?.[0]?.results?.length === 0) return "empty";

  return "loaded";
}

type responseInterfaceWithState<Data = any, Error = any> = responseInterface<Data, Error> & { state: SWRState };

export function useSWRWithState<Data = any, Error = any>(
  ...args:
    | readonly [keyInterface]
    | readonly [keyInterface, fetcherFn<Data> | null]
    | readonly [keyInterface, ConfigInterface<Data, Error> | undefined]
    | readonly [keyInterface, fetcherFn<Data> | null, ConfigInterface<Data, Error> | undefined]
): responseInterfaceWithState<Data, Error> {
  // @ts-ignore
  const res = useSWR(...args);
  return { ...res, state: calcSwrState(res) };
}
