import { usePaywallState } from "components/PaywallStateProvider";

export default function useCheckCanUseAdvancedLayout() {
  const { monetization, setPaywallModalShown, setHighlightedFeature } = usePaywallState();

  const checkCanUse = (completion) => {
    if (monetization?.data.features.others.includes("advanced_layout")) {
      completion();
    } else {
      setHighlightedFeature("advanced_layout");
      setPaywallModalShown(true);
    }
  };

  return checkCanUse;
}
