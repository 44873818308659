import { Stack } from "@mailbrew/uikit";
import ColorPicker from "components/ColorPicker";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceStyleEditor from "components/SourceStyleEditor";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const TodaySourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();

  function handleChangeColor(color) {
    dispatch(updateSourceField(sourceIndex, "accent_color", color));
  }

  return (
    <SourceEditorWrapper>
      <SourceEditorSection title="Accent Color" icon="bucket" noBorderTop>
        <Stack vertical align="stretch">
          <ColorPicker color={source.accent_color} setColor={handleChangeColor} palette="today" />
        </Stack>
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default TodaySourceEditor;
