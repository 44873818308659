import { Input } from "@mailbrew/uikit";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import useDebouncedDispatch from "hooks/useDebouncedDispatch";
import useInputValidator from "hooks/useInputValidator";
import { Fragment, useMemo } from "react";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import { pluralize } from "utils/pluralize";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const DevToSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <SourceEditorWrapper>
      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        title={`Include ${source.num_posts} popular ${pluralize("post", source.num_posts)}`}
        noBorderTop
        source={source}
        sourceIndex={sourceIndex}
        min={1}
        max={30}
        fieldName="num_posts"
      />

      {/* FILTER BY TAG */}
      <SourceEditorSection title="Filter by Tag" icon="hashtag" noPadding>
        <TagEditor source={source} sourceIndex={sourceIndex} />
      </SourceEditorSection>

      <SourceStyleOptionsWrapper>
        <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

const TagEditor = ({ source, sourceIndex }) => {
  const { data: popularTags } = useSWR("devto_popular_tags/");

  const dispatch500 = useDebouncedDispatch(500);

  const [tag, setTag] = useInputValidator(
    source.tag,
    () => null,
    (value) => {
      if (value && value.length > 0) {
        dispatch500(updateSourceField(sourceIndex, "tag", value));
      } else {
        dispatch500(updateSourceField(sourceIndex, "tag", undefined));
      }
    }
  );

  const suggestions = useMemo(() => {
    if (!popularTags) return [];
    let res = popularTags.map((t) => t.name);

    if (tag && tag.length > 0) {
      res = res.filter((t) => t.includes(tag.toLowerCase()));
    }

    return res;
  }, [popularTags, tag]);

  const handleTagSuggestionClick = (tag) => {
    setTag(tag);
  };

  return (
    <Fragment>
      <Input
        placeholder="javascript"
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        suggestions={suggestions}
        onSuggestionClick={handleTagSuggestionClick}
      />
    </Fragment>
  );
};

export default DevToSourceEditor;
