import { Switch } from "@mailbrew/uikit";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";

export default function IncludeRetweetsSection({ source, sourceIndex }) {
  const dispatch = useDispatch();

  return (
    <SourceEditorSection
      title={`Include retweets`}
      icon="userBold"
      headerExtras={
        <Switch
          on={source.include_rt > 0}
          onClick={() => {
            dispatch(updateSourceField(sourceIndex, "include_rt", !source.include_rt));
          }}
        />
      }
    ></SourceEditorSection>
  );
}
