import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import PopularOrLatestSelector from "components/editor/PopularOrLatestSelector";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import IncludeRetweetsSection from "components/IncludeRetweetsSection";
import MaxTweetsPerUserSection from "components/MaxTweetsPerUserSection";
import MaxTweetsSection from "components/MaxTweetsSection";
import PostItNote from "components/PostItNote";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import TwitterListEditorSection from "components/TwitterListEditorSection";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, updateMe } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import { notifySourcePreviewForReload } from "utils/cutom-events";
import notifApiError from "utils/notifApiError";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const minTweets = 1;
const maxPosts = 200;

const TwitterListSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const router = useRouter();
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const queryHelpId = router.query.help;

  const [helpId, setHelpId] = useState(null);

  // Set helpId and clean the URL
  useEffect(() => {
    if (queryHelpId) {
      setHelpId(queryHelpId);
      if (typeof window !== "undefined") {
        const cleanLocation = window.location.origin + window.location.pathname;
        router.push(cleanLocation, cleanLocation, { shallow: true });
      }
    }
  }, [queryHelpId, router]);

  const { data: lists, error: listsApiError } = useSWR("twitter_lists/", { revalidateOnFocus: true });

  const onSelectTwitterList = (listId) => {
    const list = lists.find((l) => l.id === listId);
    dispatch(updateSourceField(sourceIndex, "list_id", list.id));
    dispatch(updateSourceField(sourceIndex, "title", list.name));

    if (list.member_count >= 30) {
      dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", 1));
    } else if (list.member_count >= 10) {
      dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", 2));
    } else {
      dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", 3));
    }
  };

  const handleConnectedTwitterAccountChange = () => {
    notifySourcePreviewForReload(sourceIndex);
  };

  // refresh the twitter accounts to show potential errored twitter account when we can't fetch the lists
  useEffect(() => {
    if (listsApiError) {
      dispatch(updateMe());
      notifApiError(listsApiError);
    }
  }, [dispatch, listsApiError]);

  const sourceIsValid = source.list_id;

  return (
    <SourceEditorWrapper>
      {helpId === "digest" && (
        <PostItNote icon="checkmark" title="List created!">
          We've created a list for you with your selected accounts. Feel free to try another one!
        </PostItNote>
      )}
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        {/* LIST */}
        <SourceEditorSection title="List" icon="list" noBorderTop>
          <TwitterListEditorSection
            source={source}
            lists={lists}
            error={listsApiError}
            onSelectTwitterList={onSelectTwitterList}
          />
        </SourceEditorSection>

        {sourceIsValid && (
          <Fragment>
            <SourceEditorSection title="Tweets to include" icon="twitter" noBorderTop>
              {/* MODE */}
              <PopularOrLatestSelector source={source} sourceIndex={sourceIndex} />
            </SourceEditorSection>

            {/* MAX NUMBER OF TWEETS */}
            <MaxTweetsSection source={source} sourceIndex={sourceIndex} min={minTweets} max={maxPosts} />

            {/* MAX TWEETS PER USER */}
            <MaxTweetsPerUserSection source={source} sourceIndex={sourceIndex} />

            {/* Include RTs */}
            <IncludeRetweetsSection source={source} sourceIndex={sourceIndex} />

            <SourceStyleOptionsWrapper noBorderBottom>
              <SourceOptionGrid>
                <ImagesStyleSetting
                  style={source.images_style}
                  onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
                  pb={0}
                  pt={0}
                />
                <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
              </SourceOptionGrid>
              <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
              <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
            </SourceStyleOptionsWrapper>
          </Fragment>
        )}
      </Disable>

      {twitterAccountConnected && (
        <SourceEditorSection noPadding noBorderBottom>
          <ConnectedTwitterAccount accountChangeCallback={handleConnectedTwitterAccountChange} />
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

export default TwitterListSourceEditor;
