import { Center, Icon, P2, ProgressBar, SafeAreaInsetBottom, Stack } from "@mailbrew/uikit";
import { EditorDoneButton } from "pages/edit/[brewId]/[[...args]]";
import { useSelector } from "react-redux";
import { currentNewsletterSelector } from "reducers/newslettersReducer";
import { pluralize } from "utils/pluralize";

export const GAMIFICATION_PROGRESS_END = 3;

export default function EditorGamification() {
  const brew = useSelector(currentNewsletterSelector);
  const progress = brew?.sources?.length ?? 0;
  const sourcesLeft = GAMIFICATION_PROGRESS_END - progress;
  const showProgressBar = sourcesLeft > 0;

  return (
    <Center mt={5}>
      <Stack vertical align="center" gap={1.5}>
        {(() => {
          if (progress === 0) {
            return (
              <Stack vertical align="center" gap={1.5}>
                <Icon color={(c) => c.colors.c4} name="arrowUp" />
                <P2 mb={-0.5} align="center">
                  Create your digest with our sources ✨
                </P2>
                <P2 align="center">Add your first one now</P2>
              </Stack>
            );
          }
          if (progress <= GAMIFICATION_PROGRESS_END - 1) {
            return (
              <Stack vertical align="center" gap={1.5}>
                <Icon color={(c) => c.colors.c4} name="arrowUp" />
                <P2>Your brew is taking shape 🎉</P2>
                <P2>
                  Add {sourcesLeft} more {pluralize("source", sourcesLeft)}
                </P2>
              </Stack>
            );
          }
          if (progress > GAMIFICATION_PROGRESS_END - 1) {
            return (
              <Stack vertical align="center" gap={1.5}>
                <P2>Looking good 😍</P2>
                <P2 align="center">Click done when you're done creating this digest.</P2>
                <EditorDoneButton mt={2} />
              </Stack>
            );
          }
        })()}
        {showProgressBar && (
          <ProgressBar mt={2} width="120px" progress={(progress + 1) / (GAMIFICATION_PROGRESS_END + 1)} />
        )}
      </Stack>
      <SafeAreaInsetBottom />
    </Center>
  );
}
