import {
  Box,
  Button,
  DropDownMenu,
  Expandable,
  H4,
  Icon,
  Input,
  Modal,
  P1,
  P2,
  Spacer,
  Stack,
  Switch,
  useBreakpoint,
  useConfig,
  useDetectBrowser,
} from "@mailbrew/uikit";
import BrewExtraCssWrapper from "components/BrewExtraCssWrapper";
import { BrewSection } from "components/BrewHeaders/UserPrivateBrewHeader";
import { usePaywallState } from "components/PaywallStateProvider";
import PublishBrewButton, { BrewPublishModal } from "components/PublishBrewButton";
import ShareSource from "components/ShareSource";
import SourceEditorModal, { DRAGGABLE_MODAL_BREAKPOINT, SourceEditorModalHeader } from "components/SourceEditorModal";
import api from "dependencies/api";
import { motion } from "framer-motion";
import useCheckCanUseAdvancedLayout from "hooks/useCheckCanUseAdvancedLayout";
import useEventListener from "hooks/useEventListener";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import {
  addSource,
  currentSyncedNewsletterSelector,
  removeSource,
  reorderSource,
  setSourceBeingEditedIndex,
  sourceBeingEditedIdSelector,
  updateSourceField,
} from "reducers/newslettersReducer";
import useSWR from "swr";
import confirmPromise from "utils/confirmPromise";
import notifApiError from "utils/notifApiError";
import { keyBrewById } from "utils/swrKeys";
import { v4 as uuidv4 } from "uuid";
import AddSourceModal from "./AddSourceModal";
import BrewEditorSource from "./BrewEditorSource";
import BrewOptions from "./BrewOptions";
import BrewSources from "./BrewSources";
import EditorGamification from "./EditorGamification";
import SettingSwitchRow from "./SettingSwitchRow";
import SubeditorSelector from "./SubeditorSelector";

export const BREW_BREAKPOINT = 716;
const [MODAL_SOURCE, MODAL_SOURCES, MODAL_SETTINGS] = [1, 2, 3];

export const BrewEditor = () => {
  const hits = useBreakpoint([400, DRAGGABLE_MODAL_BREAKPOINT]);
  const dispatch = useDispatch();
  const { afterOnboarding } = usePaywallState();

  const [openModalId, setOpenModalId] = useState(null);
  const [previewing, setPreviewing] = useState(false);

  const [addSourceIndex, setAddSourceIndex] = useState(null);
  const [showAddSourceModal, setShowAddSourceModal] = useState(false);

  const newsletter = useSelector(currentSyncedNewsletterSelector);
  const sourceBeingEditedId = useSelector(sourceBeingEditedIdSelector);

  const [publishModalShown, setPublishModalShown] = useState(false);
  const [isNewsletterPublic, setIsNewsletterPublic] = useState(newsletter.public);
  const [sourcesPreviewWrapperKey, setSourcesPreviewWrapperKey] = useState(0);

  const [titleEditingSourceId, setTitleEditingSourceId] = useState(null);
  const titleEditingSourceIndex = newsletter.sources?.findIndex((s) => s.id === titleEditingSourceId);

  const { data: templatesCssSwr } = useSWR(`/brew_issue_css/?breakpoint=${BREW_BREAKPOINT + "px"}`);
  const templatesCss = templatesCssSwr?.css;

  const setSourceEditedIndex = useCallback(
    (index) => {
      dispatch(setSourceBeingEditedIndex(index));
    },
    [dispatch]
  );

  const handleShowSourcesModal = (index) => {
    setAddSourceIndex(index);
    setShowAddSourceModal(true);
  };

  function handleOpenModal(id) {
    // If it's not a source modal, reset the currently editing source ID
    if (id !== MODAL_SOURCE) {
      setSourceEditedIndex(null);
    }
    // If the modal is already open, close it
    if (openModalId === id) {
      setOpenModalId(null);
    } else {
      // Open the modal
      setOpenModalId(id);
    }
  }

  const handleSourceClick = useCallback(
    (sourceIndex) => {
      dispatch(setSourceBeingEditedIndex(sourceIndex));
      setPreviewing(false);
    },
    [dispatch]
  );

  const handleSourceTitleClick = useCallback((sourceId) => {
    setTitleEditingSourceId(sourceId);
  }, []);

  // Never open the editor with a source editor already open
  useEffect(() => {
    setSourceEditedIndex(null);
  }, [setSourceEditedIndex]);

  // If the user is editing a source, open the source modal
  useEffect(() => {
    if (sourceBeingEditedId) {
      setOpenModalId(MODAL_SOURCE);
    } else if (openModalId === MODAL_SOURCE) {
      setOpenModalId(null);
    }
  }, [openModalId, sourceBeingEditedId]);

  /* ------------------------ Refresh sources on edits ------------------------ */

  const [sourcesUpdatesCount, setSourcesUpdatesCount] = useState({});

  const handleReloadSource = useCallback(
    (event) => {
      const sourceIndex = event.detail.sourceIndex;
      const updatedCount = (sourcesUpdatesCount[sourceIndex] ?? 0) + 1;
      setSourcesUpdatesCount({ ...sourcesUpdatesCount, [sourceIndex]: updatedCount });
    },
    [sourcesUpdatesCount]
  );

  const handleReloadAllSource = useCallback(() => {
    setSourcesPreviewWrapperKey(sourcesPreviewWrapperKey + 1);
  }, [sourcesPreviewWrapperKey]);

  useEventListener("mailbrew:reload_source", handleReloadSource);
  useEventListener("mailbrew:reload_all_sources", handleReloadAllSource);

  if (typeof templatesCss === "undefined") return null;

  return (
    <>
      {/* ----------------------------- Options buttons ---------------------------- */}

      {!afterOnboarding && (
        <Stack w="100%" align="center" mb={4} gap={3}>
          <Button icon="settingsAlt" variant={["pill", "white"]} onClick={() => handleOpenModal(MODAL_SETTINGS)}>
            Settings
          </Button>
          <Button icon="select" variant={["pill", "white"]} onClick={() => handleOpenModal(MODAL_SOURCES)}>
            Reorder
          </Button>
          <PublishBrewButton newsletter={newsletter} variant={["pill", "white"]} stretch={false} />
        </Stack>
      )}

      {/* ----------------------------- Preview toggle ----------------------------- */}

      {!afterOnboarding && (
        <Expandable expanded={newsletter?.sources?.length > 0}>
          <Stack align="center" mb={4}>
            <Switch small on={previewing} onClick={() => setPreviewing(!previewing)} />
            <P2>Preview</P2>
          </Stack>
        </Expandable>
      )}

      {/* --------------------------------- Sources -------------------------------- */}

      <Box w="100%" maxW="100%" mx="auto">
        <BrewExtraCssWrapper
          baseCss={templatesCss}
          customCss={previewing ? `.src {margin-top: 0;}` : `.src {margin-top: 0; margin-bottom: 0;}`}
          templateVersion={5}
          key={sourcesPreviewWrapperKey}
        >
          {!newsletter.sources || newsletter.sources.length === 0 ? (
            <Stack w="100%">
              <AddSourceButton glow label="Add first source" onClick={() => handleShowSourcesModal(0)} />
            </Stack>
          ) : (
            newsletter.sources?.map((source, sourceIndex) => {
              return (
                <Stack
                  key={source.id + `${sourcesUpdatesCount[sourceIndex] ?? ""}`}
                  transition={{ duration: 0.3, bounce: 0.1 }}
                  as={motion.div}
                  initial={false}
                  layout
                  vertical
                  gap={0}
                  w="100%"
                >
                  {!previewing && (
                    <>
                      <AddSourceButton
                        glow={afterOnboarding && newsletter.sources.length < 2}
                        onClick={() => handleShowSourcesModal(sourceIndex)}
                      />
                      <SourceToolbar
                        source={source}
                        sourceIndex={sourceIndex}
                        isLast={sourceIndex === newsletter.sources.length - 1}
                      />
                    </>
                  )}
                  <BrewEditorSource
                    source={source}
                    brewId={newsletter.id}
                    isBeingEdited={sourceBeingEditedId === source.id}
                    onSourceClick={() => handleSourceClick(sourceIndex)}
                    onTitleClick={() => handleSourceTitleClick(source.id)}
                  />
                  {!previewing && sourceIndex === newsletter.sources.length - 1 && (
                    <>
                      <AddSourceButton
                        glow={afterOnboarding && newsletter.sources.length < 2}
                        onClick={() => handleShowSourcesModal(sourceIndex + 1)}
                      />
                    </>
                  )}
                </Stack>
              );
            })
          )}
          {hits[1] && <motion.div animate={{ height: sourceBeingEditedId ? "60vh" : "0vh" }} />}
        </BrewExtraCssWrapper>
      </Box>

      <TitleEditor
        source={newsletter.sources?.find((s) => s.id === titleEditingSourceId)}
        sourceIndex={titleEditingSourceIndex}
        show={titleEditingSourceId}
        setShow={setTitleEditingSourceId}
      />

      {afterOnboarding && <EditorGamification />}

      <Spacer size={8} />

      {/* --------------------------------- Modals --------------------------------- */}

      <SourceEditorModal
        show={!!openModalId && !showAddSourceModal}
        onClose={() => handleOpenModal(null)}
        maxMobileHeight={openModalId === MODAL_SOURCE ? "55vh" : "80vh"}
        fixedHeight={openModalId === MODAL_SOURCES}
      >
        <SourceEditorModalHeader>
          <ShareSource />
          <Button variant="pill" icon="chevronDown" onClick={() => handleOpenModal(null)}>
            Done
          </Button>
        </SourceEditorModalHeader>
        {openModalId === MODAL_SOURCE && <SubeditorSelector sourceId={sourceBeingEditedId} />}
        {openModalId === MODAL_SETTINGS && <BrewOptions />}
        {openModalId === MODAL_SOURCES && <BrewSources />}
      </SourceEditorModal>
      <BrewPublishModal
        isNewsletterPublic={isNewsletterPublic}
        setIsNewsletterPublic={setIsNewsletterPublic}
        newsletter={newsletter}
        show={publishModalShown}
        setShow={setPublishModalShown}
      />

      <AddSourceModal show={showAddSourceModal} setShow={setShowAddSourceModal} addSourceIndex={addSourceIndex} />
    </>
  );
};

const SourceToolbar = ({ source, sourceIndex, isLast }) => {
  const config = useConfig();
  const { hasTouch } = useDetectBrowser();
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const { data: userBrews = [] } = useSWR(user && "/newsletters/");
  const [moveToBrewShown, setMoveToBrewShown] = useState(false);

  const hasMultipleBrews = userBrews.length > 1;

  const sourceBeingEditedId = useSelector(sourceBeingEditedIdSelector);
  const isEditing = sourceBeingEditedId === source.id;

  const setSourceEditedIndex = useCallback((index) => dispatch(setSourceBeingEditedIndex(index)), [dispatch]);

  const handleDeleteSource = async () => {
    const confirmed = await confirmPromise("Remove source?");
    if (!confirmed) return;
    dispatch(removeSource(sourceIndex));
    dispatch(setSourceBeingEditedIndex(null));
  };

  const handleEdit = () => {
    isEditing ? setSourceEditedIndex(null) : setSourceEditedIndex(sourceIndex);
  };

  const handleDuplicate = () => {
    const newSource = {
      ...source,
      id: uuidv4(),
    };
    dispatch(addSource({ source: newSource, index: sourceIndex }));
  };

  const moveSource = (offset) => {
    dispatch(reorderSource({ from: sourceIndex, to: sourceIndex + offset }));
  };

  const dotsOptions = [
    {
      name: "Duplicate",
      icon: "copy",
      handler: handleDuplicate,
    },
  ];

  if (hasMultipleBrews) {
    dotsOptions.push({
      name: "Move to other brew",
      icon: "arrowRight",
      handler: () => setMoveToBrewShown(true),
    });
  }

  return (
    <>
      <BrewSection>
        <Stack align="right" w="100%" mt={hasTouch ? 1 : -1} mb={hasTouch ? -2 : -3.8}>
          <Stack
            h={hasTouch ? "34px" : "30px"}
            radius="20px"
            bg={config.colors.bg0}
            border={`1px solid ${config.colors.uiBorderColor}`}
            shadow="0 2px 2px rgba(0,0,0,0.03)"
            px={3}
            gap={hasTouch ? 3.5 : 2.5}
            zIndex="3"
          >
            <ToolbarIcon color={isEditing && config.colors.accent1} name="settings" onClick={handleEdit} />
            <ToolbarIcon name="trash" onClick={handleDeleteSource} />
            <DropDownMenu
              options={dotsOptions.map((o) => o.name)}
              optionsIcons={dotsOptions.map((o) => o.icon)}
              onSelect={(option) => dotsOptions.find((o) => o.name === option).handler()}
            >
              <ToolbarIcon name="dots" />
            </DropDownMenu>
            <ToolbarIcon name="arrowUp" onClick={() => moveSource(-1)} disabled={sourceIndex === 0} />
            <ToolbarIcon name="arrowDown" onClick={() => moveSource(1)} disabled={isLast} />
          </Stack>
        </Stack>
      </BrewSection>
      {hasMultipleBrews && (
        <MoveSourceModal
          source={source}
          sourceIndex={sourceIndex}
          show={moveToBrewShown}
          setShow={setMoveToBrewShown}
        />
      )}
    </>
  );
};

const TitleEditor = ({ source, sourceIndex, show, setShow }) => {
  const dispatch = useDispatch();
  const checkCanUse = useCheckCanUseAdvancedLayout();

  const [title, setTitle] = useState(source?.title ?? "");

  useEffect(() => {
    setTitle(source?.title ?? "");
  }, [source]);

  const showTitle = source?.show_title ?? true;

  const toggleShowTitle = () => checkCanUse(() => dispatch(updateSourceField(sourceIndex, "show_title", !showTitle)));

  function handleSave() {
    dispatch(updateSourceField(sourceIndex, "title", title));
    setShow(false);
  }

  return (
    <Modal width="400px" hideCloseButton show={show} setShow={setShow} lockScrolling={false}>
      <P1 mb={1}>Edit this source title:</P1>
      <Input
        data-hj-allow
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Source title..."
        autoFocus
        autoCorrect={false}
        autoComplete={false}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setShow(false);
          }
        }}
      />
      <Spacer size={4} />
      <SettingSwitchRow copy="Show the title" state={showTitle} onChange={toggleShowTitle} />
      <Button mt={2} w="100%" onClick={handleSave}>
        Save
      </Button>
    </Modal>
  );
};

const MoveSourceModal = ({ source, sourceIndex, show, setShow }) => {
  const dispatch = useDispatch();
  const newsletter = useSelector(currentSyncedNewsletterSelector);
  const { data: userBrews = [] } = useSWR("/newsletters/");
  const otherBrews = useMemo(() => userBrews?.filter((n) => n.id !== newsletter.id), [newsletter.id, userBrews]);

  const handleMoveSourceToOtherBrew = async (brewId) => {
    try {
      const res = await api.get(keyBrewById(brewId));
      const otherBrew = res.data;

      // Add to other brew
      if (!otherBrew.sources) {
        otherBrew.sources = [source];
      } else {
        otherBrew.sources.push(source);
      }

      // Sync the other brew
      try {
        await api.patch(keyBrewById(brewId), { sources: otherBrew.sources });
      } catch (err) {
        notifApiError(err, "Error moving source to the brew.");
        return;
      }

      // remove source from current brew
      setShow(false);
      dispatch(removeSource(sourceIndex));
      dispatch(setSourceBeingEditedIndex(null));
    } catch (err) {
      notifApiError(err, "Can't get the destination brew.");
      return;
    }
  };
  return (
    <Modal show={show} setShow={setShow}>
      <Stack mb={2} gap={1} noWrap>
        <Icon name="arrowRight" size={22} />
        <H4>Move to</H4>
      </Stack>
      <Stack vertical align="stretch">
        {otherBrews.map((brew) => (
          <Button w="100%" key={brew.id} variant={"secondary"} onClick={() => handleMoveSourceToOtherBrew(brew.id)}>
            {brew.title}
          </Button>
        ))}
      </Stack>
    </Modal>
  );
};

const ToolbarIcon = ({ disabled, color, onClick, ...otherProps }) => {
  const config = useConfig();
  const { hasTouch } = useDetectBrowser();
  return (
    <Icon
      color={disabled ? config.colors.c5 : color || config.colors.c3}
      hoverColor={disabled ? config.colors.c5 : color || config.colors.c2}
      size={hasTouch ? 18 : 14}
      strokeWidth={hasTouch ? 2 : 2.5}
      onClick={disabled ? undefined : onClick}
      {...otherProps}
    />
  );
};

const AddSourceButton = ({ glow, label = "", onClick }) => {
  const config = useConfig();
  return (
    <>
      <Stack mt={2} w="100%" align="center" gap={0}>
        <Button
          my={1.5}
          mx="auto"
          variant={glow ? "pill" : ["pill", "secondary"]}
          glow={glow}
          color={glow ? config.colors.accent1 : config.colors.c3}
          icon="plus"
          onClick={onClick}
        >
          {label}
        </Button>
      </Stack>
    </>
  );
};
