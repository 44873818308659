import { SliderWithInput } from "@mailbrew/uikit";
import SourceEditorSection from "components/editor/SourceEditorSection";
import InfoButton from "components/InfoButton";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { pluralize } from "utils/pluralize";

export default function MaxTweetsSection({ source, sourceIndex, min, max: providedMax }) {
  const dispatch = useDispatch();

  return (
    <SourceEditorSection
      icon="list"
      title={`Include up to ${source.max_tweets} ${pluralize("tweet", source.max_tweets)}`}
      headerExtras={
        <InfoButton
          title="Which tweets are included?"
          description={`We'll include at most ${source.max_tweets} tweets, tweeted since the previous issue of this brew.`}
          interactive={false}
        />
      }
    >
      <SliderWithInput
        min={min}
        max={providedMax}
        value={source.max_tweets}
        onChange={(value) => dispatch(updateSourceField(sourceIndex, "max_tweets", parseInt(value)))}
        postName="tweet"
      />
    </SourceEditorSection>
  );
}
