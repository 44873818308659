import {
  Button,
  Center,
  Divider,
  Grid,
  H3,
  Modal,
  P1,
  P2,
  Stack,
  SubtleCard,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import { sourcesData } from "data/sourcesData";
import api from "dependencies/api";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { currentNewsletterSelector, sourceBeingEditedIndexSelector } from "reducers/newslettersReducer";
import tinycolor from "tinycolor2";
import urls from "urls";
import { frontendURL } from "utils/env";
import notifApiError from "utils/notifApiError";
import { getSourceTitle } from "utils/sources";
import CopyButton from "./CopyButton";

export default function ShareSource() {
  const sourceBeingEditedIndex = useSelector(sourceBeingEditedIndexSelector);
  const newsletter = useSelector(currentNewsletterSelector);
  const source = newsletter?.sources?.[sourceBeingEditedIndex];

  const isSharable = sourcesData[source?.type]?.sharable;

  const [loading, setLoading] = useState(false);
  const [sharedSource, setSharedSource] = useState(null);

  async function handleShare() {
    setLoading(true);

    try {
      const res = await api.post("/sources/", {
        title: getSourceTitle(source),
        type: source.type,
        source: source,
      });

      setSharedSource(res.data);
    } catch (err) {
      notifApiError(err);
    } finally {
      setLoading(false);
    }
  }

  if (!isSharable) return null;

  return (
    <>
      <Button variant={["pill", "white"]} loading={loading} onClick={handleShare} icon="shareAlt">
        Share source
      </Button>
      <ShareSourceModal
        show={sharedSource !== null}
        setShow={() => setSharedSource(null)}
        sharedSource={sharedSource}
      />
    </>
  );
}

const ShareSourceModal = ({ show, setShow, sharedSource }) => {
  const config = useConfig();
  const hit = useBreakpoint();

  if (!sharedSource) return null;

  const sourceUrl = `${frontendURL}${urls.sharedSource(sharedSource.share_id)}`;
  const displayURL = sourceUrl.replace("http://", "").replace("https://", "");

  return (
    <Modal bottomSheet={hit} width="700px" show={show} setShow={setShow}>
      <Stack my={2} vertical align="center" gap={1}>
        <H3 align="center">Share a link to this source</H3>
        <P1 mb={4}>Visitors can sign up to Mailbrew and add this source.</P1>
        <Stack noWrap align="center" maxW="100%">
          <SubtleCard w="340px" maxW="100%" py={0} h={config.Button.height} flex ai="center" overflow="hidden">
            <P1 noWrap>{displayURL}</P1>
          </SubtleCard>
          <CopyButton icon="link" label="Copy" copyText={sourceUrl} />
        </Stack>
        <Divider size={5} />
        <H3 align="center">Embed an "Add&nbsp;to&nbsp;Mailbrew"&nbsp;button</H3>
        <P1 align="center" mb={3}>
          If you have a website or blog, this is a great way to let people follow its content.
        </P1>
        <SourceEmbed sourceUrl={sourceUrl} />
      </Stack>
    </Modal>
  );
};

const SourceEmbed = ({ sourceUrl }) => {
  const [selectedColors, setSelectedColors] = useState(null);
  const [textColor, setTextColor] = useState("white");
  const [logoUrl, setLogoUrl] = useState(null);

  const [buttonHtml, setButtonHtml] = useState(null);
  const [buttonJsx, setButtonJsx] = useState(null);

  useEffect(() => {
    if (selectedColors) {
      if (tinycolor(selectedColors[0]).getBrightness() > 200) {
        setTextColor("rgb(20,20,20)");
        setLogoUrl("https://assets.mailbrew.com/logo/mailbrew-36px-black.png");
      } else {
        setTextColor("white");
        setLogoUrl("https://assets.mailbrew.com/logo/mailbrew-36px-white.png");
      }
      setButtonHtml(getButtonHtmlCode(sourceUrl, textColor, selectedColors, logoUrl));
      setButtonJsx(getButtonReactCode(sourceUrl, textColor, selectedColors, logoUrl));
    }
  }, [logoUrl, selectedColors, sourceUrl, textColor]);

  return (
    <Grid w="100%" breakAt={500}>
      <Center>
        <Stack vertical align="center">
          <Stack align="center" mb={2}>
            <ColorDot color={"#f75858"} onSelect={setSelectedColors} isDefault />
            <ColorDot color={"rgb(30,30,30)"} onSelect={setSelectedColors} />
            <ColorDot color={"rgb(255,255,255)"} onSelect={setSelectedColors} />
          </Stack>
          <CopyButton w="100%" icon="html" variant="white" copyText={buttonHtml}>
            Copy HTML code
          </CopyButton>
          <CopyButton w="100%" icon="react" variant="white" copyText={buttonJsx}>
            Copy React Component
          </CopyButton>
        </Stack>
      </Center>
      <SubtleCard h="150px" flex ai="center" jc="center" position="relative">
        <P2
          align="center"
          style={{
            position: "absolute",
            top: 8,
            left: 0,
            right: 0,
          }}
        >
          Preview
        </P2>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
          <IFrameWithCustomHtml html={buttonHtml} />
        </div>
      </SubtleCard>
    </Grid>
  );
};

const IFrameWithCustomHtml = ({ html }) => {
  return (
    <iframe
      title="html"
      style={{
        border: 0,
        width: "100%",
        overflow: "hidden",
        height: "50px",
      }}
      srcDoc={html}
    />
  );
};

function getButtonHtmlCode(url, textColor, colors, logoUrl) {
  return `<div style="text-align: center"><a href="${url}" target="_blank" style="display: inline-flex; align-items: center; text-align: center; background: ${colors[0]}; padding: 4px 12px 6px; border-radius: 8px; border: 1px solid ${colors[1]}; text-decoration: none;">
  <img alt="Mailbrew Logo" src="${logoUrl}" style="display: inline-block; height: 18px; width: 18px; margin-right: 5px;" />
  <span style="font-family: -apple-system, system-ui, 'Helvetica Neue', 'Helvetica', sans-serif; color: ${textColor}; line-height: 1.5; font-size: 15px; font-weight: 400;">Add to Mailbrew</span>
</a></div>`;
}

function getButtonReactCode(url, textColor, colors, logoUrl) {
  return `<div style={{ textAlign: "center" }}>
  <a
    href="${url}"
    target="_blank"
    rel="noreferrer"
    style={{
      display: "inline-flex",
      alignItems: "center",
      textAlign: "center",
      background: "${colors[0]}",
      padding: "4px 12px 6px",
      borderRadius: "8px",
      border: "1px solid ${colors[1]}",
      textDecoration: "none",
    }}
  >
    <img
      alt="Mailbrew Logo"
      src="${logoUrl}"
      style={{
        display: "inline-block",
        height: "18px",
        width: "18px",
        marginRight: "5px",
      }}
    />
    <span
      style={{
        fontFamily:
          "-apple-system, system-ui, 'Helvetica Neue', 'Helvetica', sans-serif",
        color: "${textColor}",
        lineHeight: "1.5",
        fontSize: "15px",
        fontWeight: 400,
      }}
    >
      Add to Mailbrew
    </span>
  </a>
</div>`;
}

const ColorDot = ({ color, onSelect, isDefault }) => {
  const borderColor = useMemo(() => {
    return tinycolor(color).isDark() ? tinycolor(color).lighten(10).toString() : tinycolor(color).darken(10).toString();
  }, [color]);

  useEffect(() => {
    if (isDefault) {
      onSelect?.([color, borderColor]);
    }
  }, [borderColor, color, isDefault, onSelect]);

  return (
    <div
      onClick={() => onSelect?.([color, borderColor])}
      style={{
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        backgroundColor: color,
        border: `1px solid ${borderColor}`,
        cursor: "pointer",
      }}
    />
  );
};
