import { Icon, useConfig } from "@mailbrew/uikit";

export const RemoveButton = ({ onClick, disabled }) => {
    const config = useConfig();
    return (
        <Icon
            color={disabled ? config.colors.c5 : config.colors.accent1}
            name="minusBold"
            size="20px"
            onClick={disabled ? undefined : onClick}
        />
    );
};
