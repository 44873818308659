import { P2 } from "@mailbrew/uikit";
import SourceCard from "components/editor/SourceCard";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import { usePaywallState } from "components/PaywallStateProvider";
import { useMonetizationFeatures } from "hooks/monetizationHooks";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  currentNewsletterSelector,
  removeSource,
  reorderSource,
  setSourceBeingEditedIndex,
} from "reducers/newslettersReducer";
import confirmPromise from "utils/confirmPromise";

const BrewSources = () => {
  const dispatch = useDispatch();

  const newsletter = useSelector(currentNewsletterSelector);
  const { sources } = newsletter;

  useEffect(() => dispatch(setSourceBeingEditedIndex(null)), [dispatch]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    let from = result.source.index,
      to = result.destination.index;
    dispatch(reorderSource({ from, to }));
  };

  return (
    <SourceEditorWrapper>
      {sources?.length > 0 ? (
        <SourceEditorSection subtitle="Reorder or remove your sources here." icon="login" noBorders>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              // Reparenting to fix an issue with a parent who has a transform
              // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
              renderClone={(provided, _, rubric) => (
                <DraggableClone
                  key={sources[rubric.source.index].id}
                  newsletter={newsletter}
                  source={sources[rubric.source.index]}
                  sourceIndex={rubric.source.index}
                  provided={provided}
                />
              )}
              droppableId="droppable"
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sources &&
                    sources.map((source, index) => {
                      return (
                        <DraggableSource
                          key={source.id}
                          newsletter={newsletter}
                          source={source}
                          sourceIndex={index}
                          offset={0}
                        />
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </SourceEditorSection>
      ) : (
        <SourceEditorSection noBorders>
          <P2>You'll be able to reorder & remove sources here.</P2>
        </SourceEditorSection>
      )}
    </SourceEditorWrapper>
  );
};

const DraggableClone = ({ provided, newsletter, source, sourceIndex }) => {
  return (
    <SourceCard
      mode="edit"
      sourceType={source.type}
      source={source}
      style={{ border: "1px solid red" }}
      provided={provided}
    />
  );
};

const DraggableSource = ({ newsletter, source, sourceIndex, offset }) => {
  const dispatch = useDispatch();
  const { isSourceLocked } = useMonetizationFeatures();

  const { setPaywallModalShown } = usePaywallState();

  const isLocked = isSourceLocked(source.type);

  const handleSourceCardClick = () => {
    if (!isLocked) {
      dispatch(setSourceBeingEditedIndex(sourceIndex));
    } else {
      setPaywallModalShown(true);
    }
  };

  const handleDeleteSource = async () => {
    const confirmed = await confirmPromise("Remove this source?");
    if (!confirmed) return;
    dispatch(removeSource(sourceIndex + offset));
    dispatch(setSourceBeingEditedIndex(null));
  };

  return (
    <Draggable draggableId={source.id} index={sourceIndex}>
      {(provided) => (
        <SourceCard
          mode="edit"
          sourceType={source.type}
          source={source}
          onClick={handleSourceCardClick}
          onRemoveClick={handleDeleteSource}
          style={{ border: "1px solid red" }}
          provided={provided}
        />
      )}
    </Draggable>
  );
};

export default BrewSources;
