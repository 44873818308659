import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SearchField from "components/editor/SearchField";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import useRandomItem from "hooks/useRandomItem";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { resetError } from "reducers/appReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import { pluralize } from "utils/pluralize";
import { searchYouTubeChannels } from "utils/sourceSuggestionFetchers";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const YouTubePlaylistSourceEditor = (props) => {
  const { source, sourceIndex, dispatch } = props;

  const sourceIsValid = source?.playlist_id;

  const placeholder = useRandomItem([
    "Marques Brownlee",
    "The Verge",
    "CNET",
    "Austin Evans",
    "Google Tech Talks",
    "Unbox Therapy",
    "TED",
    "Vsauce",
    "Linus Tech Tips",
    "NASA",
    "Jonathan Morrison",
    "The Nerdwriter",
    "Wisecrack",
    "FilmmakerIQ",
    "PewDiePie",
    "No Small Parts",
    "Fandor",
    "Chris Stuckmann",
    "First Ten Minutes",
    "Glowing Screens",
    "Folding Ideas",
    "Kaptain Kristian",
    "Lessons from the screenplay",
  ]);

  useEffect(() => dispatch(resetError("youtube_playlist")), [dispatch, source]);

  return (
    <SourceEditorWrapper>
      <SourceEditorSection title="YouTube Channel or Playlist" icon="youtube" noBorderTop>
        <SearchField
          initialValue={source.title}
          placeholder={placeholder}
          autoFocus={!source.playlist_id}
          fetchSuggestions={searchYouTubeChannels}
          autofillSuggestionClick="name"
          onSuggestionClick={(playlistID, title) => {
            dispatch(updateSourceField(sourceIndex, "playlist_id", playlistID));
            dispatch(updateSourceField(sourceIndex, "title", title));
          }}
        />
      </SourceEditorSection>

      {sourceIsValid && (
        <Fragment>
          <NumPostsSourceEditor
            title={`Include ${source.max_videos} most recent ${pluralize("video", source.max_videos)}`}
            source={source}
            sourceIndex={sourceIndex}
            fieldName="max_videos"
            min={1}
            max={20}
          />

          <SourceStyleOptionsWrapper>
            <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
            <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
            <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
          </SourceStyleOptionsWrapper>
        </Fragment>
      )}
    </SourceEditorWrapper>
  );
};

export default connect((state) => ({
  newsletter: state.newsletters.currentNewsletter,
  loading: state.app.loading,
  errors: state.app.errors,
}))(YouTubePlaylistSourceEditor);
