import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import { colorPickerPalette } from "components/editor/PaletteColorPicker";
import PostBodyOption from "components/editor/PostBodyOption";
import QuoteMode from "components/editor/QuoteMode";
import SearchField from "components/editor/SearchField";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SourceEditorWrapper from "components/editor/SourceEditorWrapper";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import SourceStyleEditor from "components/SourceStyleEditor";
import SourceTitleEditorSection from "components/SourceTitleEditorSection";
import useRandomItem from "hooks/useRandomItem";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import { pluralize } from "utils/pluralize";
import { fetchRedditSuggestions } from "utils/sourceSuggestionFetchers";
import NumColumnsSourceEditor from "../NumColumnsSourceEditor";
import SourceOptionGrid from "../SourceOptionGrid";
import SourceStyleOptionsWrapper from "../SourceStyleOptionsWrapper";

const SubredditSourceEditor = (props) => {
  const { source, sourceIndex } = props;
  const dispatch = useDispatch();

  const placeholder = useRandomItem([
    "NoNoNoNoYes",
    "AskReddit",
    "HoldMyBeer",
    "Aww",
    "TodayILearned",
    "Facepalm",
    "CrappyDesign",
    "MildlyVandalized",
  ]);

  return (
    <SourceEditorWrapper>
      <SourceEditorSection noBorderTop title="Subreddit" icon="reddit">
        <SearchField
          initialValue={source.subreddit}
          placeholder={placeholder}
          autoFocus={!(source.subreddit && source.subreddit.length > 0)}
          fetchSuggestions={fetchRedditSuggestions}
          onSuggestionClick={(suggestion) => {
            dispatch(updateSourceField(sourceIndex, "subreddit", suggestion));
          }}
        />
      </SourceEditorSection>

      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        title={`Include ${source.num_posts} popular ${pluralize("post", source.num_posts)}`}
        source={source}
        sourceIndex={sourceIndex}
        min={1}
        max={10}
        fieldName="num_posts"
      />

      <QuoteMode
        enabled={source.quote_mode}
        onEnabledChange={() => {
          const enabled = !source.quote_mode;

          dispatch(updateSourceField(sourceIndex, "quote_mode", !source.quote_mode));

          if (enabled) {
            dispatch(updateSourceField(sourceIndex, "style", "simple"));
          }

          // set default color  and text option when first enabling the quote option
          if (enabled && !source.quote_color)
            dispatch(updateSourceField(sourceIndex, "quote_color", colorPickerPalette.backgrounds[0]));
          if (enabled && !source.quote_text_option)
            dispatch(updateSourceField(sourceIndex, "quote_text_option", "title"));
        }}
        color={source.quote_color}
        onColorChange={(color) => dispatch(updateSourceField(sourceIndex, "quote_color", color))}
        quoteTextOption={source.quote_text_option}
        onQuoteTextOptionChange={(opt) => dispatch(updateSourceField(sourceIndex, "quote_text_option", opt))}
      />

      <SourceStyleOptionsWrapper>
        <SourceOptionGrid>
          <ImagesStyleSetting
            disabled={source.quote_mode}
            style={source.images_style}
            onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
            pb={0}
            pt={0}
          />
          <PostBodyOption
            source={source}
            sourceIndex={sourceIndex}
            defaultOption="excerpt"
            disabled={source.quote_mode}
            pb={0}
          />
          <NumColumnsSourceEditor source={source} sourceIndex={sourceIndex} />
        </SourceOptionGrid>
        <SourceStyleEditor source={source} sourceIndex={sourceIndex} />
        <SourceTitleEditorSection source={source} sourceIndex={sourceIndex} />
      </SourceStyleOptionsWrapper>
    </SourceEditorWrapper>
  );
};

export default SubredditSourceEditor;
