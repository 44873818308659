import { Input } from "@mailbrew/uikit";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, updateNewsletterField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from "yup";
import SourceEditorSection from "./SourceEditorSection";

const TitleEditor = ({ sectionTitle = "Title", children, style, noBorderTop }) => {
  const dispatch = useDispatch();
  const newsletter = useSelector(currentNewsletterSelector);

  const [debouncedUpdateTitle] = useDebouncedCallback(
    (title) => {
      dispatch(updateNewsletterField("title", title));
    },
    500,
    {
      leading: true,
    }
  );

  const [title, setTitle, titleError] = useInputValidator(
    newsletter.title,
    Yup.string().required("Please enter a title"),
    (title) => {
      if (newsletter.untitled) dispatch(updateNewsletterField("untitled", false));
      debouncedUpdateTitle(title);
    }
  );

  // const placeholder = useRandomItem(placeholders);

  return (
    <SourceEditorSection noPadding style={noBorderTop ? { borderTop: "none", ...style } : { ...style }}>
      <Input
        name="title"
        autoFocus={newsletter.untitled}
        autoComplete="off"
        type="text"
        placeholder={"Title"}
        value={!newsletter.untitled ? title : ""}
        onChange={(e) => setTitle(e.target.value)}
        error={titleError}
        style={{ width: "100%" }}
      />
      {children && children}
    </SourceEditorSection>
  );
};

export default TitleEditor;
