import { Box, getColorFromCssVariable, P2, Spinner, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { DRAGGABLE_MODAL_BREAKPOINT } from "components/SourceEditorModal";
import { sourcesData } from "data/sourcesData";
import { motion } from "framer-motion";
import useDelayedValue from "hooks/useDelayedValue";
import usePrevious from "hooks/usePrevious";
import { memo, useEffect, useMemo, useRef } from "react";
import useSWR, { mutate } from "swr";
import tinycolor from "tinycolor2";
import fastHash from "utils/fastHash";
import makeLinksTargetBlank from "utils/makeLinksTargetBlank";

const BrewEditorSource = ({ brewId, source, onSourceClick, onTitleClick, isBeingEdited }) => {
  const hit = useBreakpoint(DRAGGABLE_MODAL_BREAKPOINT);
  const previewURL = `brew_source_preview/${brewId}/${source.id}/`;
  const sourceHash = fastHash(JSON.stringify(source));
  const previousHash = usePrevious(sourceHash);
  const containerRef = useRef(null);

  const { data: previewHTML, isValidating, error } = useSWR(previewURL, { dedupingInterval: 0 });
  const sourceNeedsConfiguration = useMemo(() => isEmptyDomNode(previewHTML), [previewHTML]);

  const uiState = (() => {
    if (error) return "error";
    if (sourceNeedsConfiguration && !isValidating) return "force_edit";
    if (previewHTML && isValidating) return "refreshing_content";
    if (!previewHTML) return "loading";
    return "loaded";
  })();

  // Reload preview when source changes
  useEffect(() => {
    if (previousHash && previousHash !== sourceHash) {
      mutate(previewURL);
    }
  }, [sourceHash, previewURL, previousHash]);

  // Make all the links inside the source open in a new tab
  useEffect(() => {
    makeLinksTargetBlank(containerRef.current);
  }, [containerRef, previewHTML]);

  const handleStartEditingSource = () => {
    onSourceClick && onSourceClick(source.id);
  };

  function handleClick(e) {
    if (Array.from(e.target?.classList).includes("src-title-link")) {
      e.preventDefault();
      onTitleClick && onTitleClick();
    }

    if (!e.target || !e.target?.href) {
      handleStartEditingSource();
      onSourceClick && onSourceClick(source.id);
    }
  }

  return (
    <Box
      key={sourceHash + (previewHTML ? "loaded" : "loading")}
      data-source-id={source.id}
      w="100%"
      minHeight="50px"
      ref={containerRef}
      position="relative"
      onClick={handleClick}
      style={{
        position: "relative",
        scrollMargin: 40,
        cursor: "pointer",
      }}
    >
      {!hit && <EditingEffect show={isBeingEdited} />}
      {uiState === "error" && <P2>Error loading the source preview.</P2>}
      {uiState === "force_edit" && <EditButton onClick={handleStartEditingSource} sourceType={source.type} />}
      {(uiState === "refreshing_content" || uiState === "loading") && (
        <>
          <SourceLoadingOverlay loading />
          <div dangerouslySetInnerHTML={{ __html: previewHTML }} />
        </>
      )}
      {uiState === "loaded" && (
        <>
          <div dangerouslySetInnerHTML={{ __html: previewHTML }} />
        </>
      )}
    </Box>
  );
};

const EditButton = ({ onClick, sourceType }) => {
  const config = useConfig();
  const SourceThumb = sourcesData[sourceType].thumb;

  return (
    <Stack align="center" py={2} onClick={onClick}>
      <Stack background={config.colors.c6} pl={2.5} pr={3} py={2} radius={2} noWrap gap={2}>
        <SourceThumb width="20px" height="20px" />
        <P2>Edit...</P2>
      </Stack>
    </Stack>
  );
};

const EditingEffect = ({ show }) => {
  const config = useConfig();
  return (
    <Box
      as={motion.div}
      initial={false}
      animate={{
        width: show ? "5px" : "0px",
      }}
      transition={{ type: "spring", duration: 0.3, bounce: 0.05 }}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        borderRadius: "0 3px 3px 0",
        background: config.colors.accent1,
        zIndex: 2,
      }}
    />
  );
};

const SourceLoadingOverlay = ({ loading }) => {
  const config = useConfig();
  const backgroundColor = useMemo(
    () => tinycolor(getColorFromCssVariable(config.colors.bg0)).setAlpha(0.5).toString(),
    [config.colors.bg0]
  );

  const delayedLoading = useDelayedValue(loading, 500, true);

  return (
    <Box
      flex
      ai="center"
      jc="center"
      position="absolute"
      top={0}
      left={0}
      w="100%"
      h="100%"
      background={backgroundColor}
      zIndex={1}
      style={{ opacity: delayedLoading ? 1 : 0, transition: "0.2s", pointerEvents: "none", userSelect: "none" }}
    >
      {delayedLoading && <Spinner color={config.colors.c3} />}
    </Box>
  );
};

function isEmptyDomNode(string) {
  var doc = new DOMParser().parseFromString(string, "text/xml");
  if (!doc) return true;
  const hasChildren = !!doc.querySelector(".src")?.childElementCount;
  if (hasChildren) return false;
  return true;
}

export default memo(BrewEditorSource);
