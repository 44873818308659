export default function numberValidator(min, max) {
  return (value) => {
    const number = parseInt(value);

    if (isNaN(number)) {
      return "Please insert a number";
    }
    if (!(min <= number && number <= max)) {
      return `Must be between ${min} and ${max}`;
    }

    return null;
  };
}

export function floatValidator(value) {
  const errorMessage = "Please enter a valid number, and use the dot as decimal separator.";

  try {
    const number = parseFloat(value);
    if (isNaN(number)) return errorMessage;
    return null;
  } catch {
    return errorMessage;
  }
}

export function floatOrEmptyValidator(value) {
  if (!value || value.length === 0) {
    return null;
  } else {
    return floatValidator(value);
  }
}
